import React, { useEffect } from "react";
import { Fragment } from "react";
import logoPrincipal from "../Imagenes/Nuevo Logo oficial de Sublimat (inicio).png";
import "../Styles/Header.css";
import Btns from "./Btns";
import { Link } from "react-router-dom";
import { IoOptionsOutline } from "react-icons/io5";
import BtnsResponsive from "./BtnsResponsive";
import { useDispatch, useSelector } from "react-redux";
import { auth } from "../Redux/firebase";
import { headerdata } from "../Redux/Headerdata";
import { obtenercarrito } from "../Redux/basket";

const Header = () => {
  const user = auth.currentUser;
  const dispatch = useDispatch();
  const carrito = useSelector((store) => store.basket.basket);
  const usuariodat = useSelector((store) => store.headerdato.headerdato);

  useEffect(() => {
    if (user) {
      datos();
    } else {
      dispatch(obtenercarrito());
    }
    //eslint-disable-next-line
  }, [user]);

  const datos = async () => {
    dispatch(headerdata(user.uid));
    dispatch(obtenercarrito());
  };

  return (
    <Fragment>
      <input type="checkbox" id="menuheader" />
      <div className="header">
        <Link className="logo" to="/">
          <img
            src={logoPrincipal}
            alt="Logo de Sublimat"
            className="Logo_de_Sublimat"
          />
        </Link>
        <button className="opciones">
          <label htmlFor="menuheader">
            <IoOptionsOutline />
          </label>
        </button>
        <Btns user={user} usuariodat={usuariodat} carrito={carrito} />
      </div>
      <div className="menuresponsive">
        <BtnsResponsive user={user} usuariodat={usuariodat} carrito={carrito} />
      </div>
    </Fragment>
  );
};

export default Header;
