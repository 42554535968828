import React from "react";
import { useState } from "react";
import Aprobarverificacion from "./Aprobarverificacion";
import Correo from "./Correo";

function Solicitud(props) {
  const [rechazoform, setRechazoform] = useState(false);

  return (
    <>
      <div key={props.info.usuario} className="solicitud">
        <p>{props.info.correo}</p>
        {rechazoform ? (
          <Correo info={props.info} close={setRechazoform} />
        ) : null}
        <h4 className="nombredeusuario">{props.info.usuario}</h4>
        <div className="fotoYbotones">
          <a href={props.info.foto} target="_blank" rel="noreferrer">
            <img src={props.info.foto} alt="" className="selfie" />
          </a>
          <div className="botones">
            <Aprobarverificacion
              id={props.info.usuario}
              correo={props.info.correo}
              username={props.info.username}
            />
            <button className="rechazar" onClick={() => setRechazoform(true)}>
              Rechazar
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default Solicitud;
