import React from "react";
import { useState } from "react";
import Confirmacioneliminar from "./Confirmacioneliminar";
import { BiLike } from "react-icons/bi";
import { BsDownload } from "react-icons/bs";
import { FiShoppingCart } from "react-icons/fi";
import { MdDeleteForever, MdRemoveShoppingCart } from "react-icons/md";
import "../Styles/Post.css";
import { CgCloseO } from "react-icons/cg";
import { useDispatch, useSelector } from "react-redux";
import { agregarcarrito, eliminarcarrito } from "../Redux/basket";
import Load from "./Load";
import { auth } from "../Redux/firebase";
import { addlike, buscarlikes, deletelike } from "../Redux/Like";
import Login from "./Login";
import { useEffect } from "react";
import { descargar } from "../Redux/descargar";
import { GrClose } from "react-icons/gr";
import Productpage from "./Productpage";
import Total from "./Total";

function Posts(props) {
  const user = auth.currentUser;
  const dispatch = useDispatch();
  const [editando, setEditando] = useState(false);
  const [imagen, setImagen] = useState(false);
  const [confirmardescarga, setConfirmardescarga] = useState(false);
  const precio = [];

  if (props) {
    precio.push(props.info.id);
  }

  const control = {
    abrir: () => {
      setEditando(true);
    },
    cerrar: () => {
      setEditando(false);
    },
  };

  const agregarlike = () => {
    if (user) {
      dispatch(addlike(props.info.imageid));
    } else {
      open();
    }
  };

  function open() {
    var mostrarLogin = document.getElementById("ventanaLogin");
    mostrarLogin.style.display = "block";
  }
  function cerrar() {
    var mostrarLogin = document.getElementById("ventanaLogin");
    mostrarLogin.style.display = "none";
  }

  const productver = {
    cerrar: () => {
      setImagen(false);
    },
  };

  return (
    <div className="itemcontener">
      <button
        onClick={() => setImagen(true)}
        className="item"
        key={props.info.imageid}
        style={{
          backgroundImage: `url(${props.info.previo})`,
          backgroundSize: "contain",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
        }}>
        <div className="marcaagua"></div>
      </button>
      <div className="popUpDeLogin" id="ventanaLogin">
        <button className="close" onClick={cerrar}>
          <CgCloseO />
        </button>
        <Login />
      </div>
      {editando ? (
        <Confirmacioneliminar
          funct={control}
          fotoId={props.info.id}
          allId={props.info.imageid}
          usuario={props.info.autor}
        />
      ) : null}
      <p className="imagePrecio">
        {Number(props.info.price) === 0 ? "Gratis" : `$${props.info.price}.00`}
      </p>
      <div className="botones">
        {props.likes !== undefined &&
          (props.likes ? (
            <button
              onClick={() => dispatch(deletelike(props.info.imageid))}
              className="liked">
              <BiLike />
            </button>
          ) : (
            <button onClick={() => agregarlike()}>
              <BiLike />
            </button>
          ))}
        <button onClick={() => dispatch(agregarcarrito(props.info, true))}>
          <BsDownload />
        </button>
        {/* {user ? (
          <button onClick={() => setConfirmardescarga(!confirmardescarga)}>
            {confirmardescarga ? <GrClose /> : <BsDownload />}
          </button>
        ) : (
          <button onClick={() => dispatch(agregarcarrito(props.info, true))}>
            <BsDownload />
          </button>
        )} */}
        {props.own ? (
          <button className="eliminar" onClick={control.abrir}>
            <MdDeleteForever />
          </button>
        ) : (
          <button onClick={() => dispatch(agregarcarrito(props.info))}>
            <FiShoppingCart />
          </button>
        )}
      </div>
      {imagen ? (
        <Productpage close={productver} datos={props.info} precios={precio} />
      ) : null}
      {confirmardescarga ? <Total precios={precio} /> : null}
    </div>
  );
}

export default Posts;

//dispatch(descargar(props.info.imageid, props.info.autor))
//setConfirmardescarga(true)
