import React, { Fragment } from "react";
import Login from "./Login";
import { BiUser } from "react-icons/bi";
import { RiShoppingCartLine } from "react-icons/ri";
import "bootstrap/dist/css/bootstrap.min.css";
import { Link } from "react-router-dom";
import "../Styles/Btns.css";
import { useDispatch, useSelector } from "react-redux";
import Menudesplegable from "./Menudesplegable";
import { CgCloseO } from "react-icons/cg";
import { useEffect } from "react";
import { auth } from "../Redux/firebase";
import { headerdata } from "../Redux/Headerdata";

function Btns(props) {
  function open() {
    if (props.user) {
      if (props.usuariodat !== undefined && props.usuariodat !== null) {
        window.location.replace(`/${props.usuariodat}`);
      }
    } else {
      var mostrarLogin = document.getElementById("ventanaLogin");
      mostrarLogin.style.display = "block";
    }
  }
  function cerrar() {
    var mostrarLogin = document.getElementById("ventanaLogin");
    mostrarLogin.style.display = "none";
  }

  return (
    <Fragment>
      <ul className="BotonesDeHeader">
        <li>
          <Menudesplegable />
        </li>
        <li>
          <button
            title={
              props.user &&
              props.usuariodat !== undefined &&
              props.usuariodat !== null
                ? props.usuariodat
                : "usuario"
            }
            onClick={open}
            className="link">
            {props.user ? (
              <BiUser className="icono2" />
            ) : (
              <BiUser className="icono" />
            )}
          </button>
        </li>
        <li>
          <Link title="carrito" to="/carrito" className="link">
            <RiShoppingCartLine className="icono" />
            {props.user ? (
              props.carrito !== undefined ? (
                props.carrito.carrito !== undefined ? (
                  <p>{props.carrito.carrito.length}</p>
                ) : (
                  <p>0</p>
                )
              ) : (
                <p>0</p>
              )
            ) : props.carrito !== undefined ? (
              props.carrito !== undefined ? (
                <p>{props.carrito.length}</p>
              ) : (
                <p>0</p>
              )
            ) : (
              <p>0</p>
            )}
          </Link>
        </li>
      </ul>
      <div className="popUpDeLogin" id="ventanaLogin">
        <button className="close" onClick={cerrar}>
          <CgCloseO />
        </button>
        <Login />
      </div>
    </Fragment>
  );
}

export default Btns;
