import React from "react";
import "../Styles/popUp.css";
import { AiOutlineCloseCircle } from "react-icons/ai";
import popUp from "../Imagenes/popupImage.png";

const PopUp = ({ setShowPopUp }) => {
  const noShowpopUpAgain = () => {
    localStorage.setItem("notShowPopUp", JSON.stringify(true));
    setShowPopUp(false);
  };

  return (
    <div className="popUpContainer">
      <div className="contentContainer">
        <div className="imageContainer">
          <img src={popUp} alt="popUp Sublimat" className="popUpImage" />
          <button
            className="closeButtonPopUp"
            title="No volver a mostrar"
            onClick={noShowpopUpAgain}>
            <AiOutlineCloseCircle />
          </button>
        </div>
      </div>
    </div>
  );
};

export default PopUp;
