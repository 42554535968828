import React, { useState } from "react";
import { Link } from "react-router-dom";
import logo from "../Imagenes/Nuevo Logo oficial de Sublimat.png";
import "../Styles/Barralateral.css";
import { Fragment } from "react";
import { logout } from "../Redux/Login";
import { useDispatch } from "react-redux";
import { IoIosArrowForward } from "react-icons/io";
import { CgProfile } from "react-icons/cg";
import { AiOutlineCloudUpload } from "react-icons/ai";
import { FaHistory } from "react-icons/fa";
import { FiSettings } from "react-icons/fi";
import { MdSettingsEthernet } from "react-icons/md";
import { BsGraphUp } from "react-icons/bs";
import { FaMoneyCheckAlt } from "react-icons/fa";
import { useEffect } from "react";

const Barralateral = (props) => {
  const dispatch = useDispatch();
  const [barraloaded, setBarraloaded] = useState(false);
  const usuario = JSON.parse(sessionStorage.getItem("currentuser"));
  useEffect(() => {
    if (usuario) {
      setBarraloaded(true);
    }
  }, []);

  return (
    <Fragment>
      {barraloaded ? (
        <>
          <input type="checkbox" id="menuboton" />
          <div className="barraContainer" id="bar">
            <nav className="barra">
              <Link className="imagenLink" to="/">
                <img src={logo} alt="logo de sublimat" className="logo" />
              </Link>
              <hr />
              <ul className="menu">
                <Link className="opcionMenu" to={`/${usuario.data.username}`}>
                  <li>
                    <CgProfile className="icono" /> Mi perfil
                  </li>
                </Link>
                {props.rol.rol !== undefined && props.rol.rol !== null ? (
                  props.rol.rol.autor || props.rol.rol.admin ? (
                    <Link
                      className="opcionMenu"
                      to={`/${usuario.data.username}/upload`}>
                      <li>
                        <AiOutlineCloudUpload className="icono" /> Subir imagen
                      </li>
                    </Link>
                  ) : null
                ) : localStorage.getItem("rol") ? (
                  JSON.parse(localStorage.getItem("rol")).rol.autor ||
                  JSON.parse(localStorage.getItem("rol")).rol.admin ? (
                    <Link
                      className="opcionMenu"
                      to={`/${usuario.data.username}/upload`}>
                      <li>
                        <AiOutlineCloudUpload className="icono" /> Subir imagen
                      </li>
                    </Link>
                  ) : null
                ) : null}
                <Link
                  className="opcionMenu"
                  to={`/${usuario.data.username}/historial`}>
                  <li>
                    <FaHistory className="icono" /> Historial
                  </li>
                </Link>
                <Link className="opcionMenu" to="/cuenta/ajustes">
                  <li>
                    <FiSettings className="icono" /> Ajustes
                  </li>
                </Link>
                {props.rol.rol !== undefined && props.rol.rol !== null ? (
                  props.rol.rol.admin ? (
                    <Link className="opcionMenu" to="/solicitudes">
                      <li>
                        <MdSettingsEthernet className="icono" /> Solicitudes
                      </li>
                    </Link>
                  ) : null
                ) : localStorage.getItem("rol") ? (
                  JSON.parse(localStorage.getItem("rol")).rol.admin ? (
                    <Link className="opcionMenu" to="/solicitudes">
                      <li>
                        <MdSettingsEthernet className="icono" /> Solicitudes
                      </li>
                    </Link>
                  ) : null
                ) : null}
                {props.rol.rol !== undefined && props.rol.rol !== null ? (
                  props.rol.rol.admin ? (
                    <Link className="opcionMenu" to="/ventas">
                      <li>
                        <FaMoneyCheckAlt className="icono" /> Ventas
                      </li>
                    </Link>
                  ) : null
                ) : localStorage.getItem("rol") ? (
                  JSON.parse(localStorage.getItem("rol")).rol.admin ? (
                    <Link className="opcionMenu" to="/ventas">
                      <li>
                        <FaMoneyCheckAlt className="icono" /> Ventas
                      </li>
                    </Link>
                  ) : null
                ) : null}
                {/* {props.rol.rol !== undefined && props.rol.rol !== null ? (
                  props.rol.rol.admin ? (
                    <Link className="opcionMenu" to="/compras">
                      <li>
                        <BsGraphUp className="icono" /> Compras
                      </li>
                    </Link>
                  ) : null
                ) : localStorage.getItem("rol") ? (
                  JSON.parse(localStorage.getItem("rol")).rol.admin ? (
                    <Link className="opcionMenu" to="/Compras">
                      <li>
                        <BsGraphUp className="icono" /> Compras
                      </li>
                    </Link>
                  ) : null
                ) : null} */}
                {/* {
                                  (props.rol.rol !== undefined && props.rol.rol !== null)?(
                                      (props.rol.rol.admin)?(
                                          <Link className='opcionMenu' to='/user/allimages'>
                                              <li>Todas las imagenes</li>
                                          </Link>
                                      ):(
                                          null
                                      )
                                  ):(
                                      (localStorage.getItem('rol'))?(
                                          (JSON.parse(localStorage.getItem('rol')).rol.admin)?(
                                              <Link className='opcionMenu' to='/user/allimages'>
                                                  <li>Todas las imagenes</li>
                                              </Link>
                                          ):(
                                              null
                                          )
                                      ):(
                                          null
                                      )
                                  )
                              } */}
              </ul>
              <button
                onClick={() => {
                  dispatch(logout());
                }}
                className="logoutboton">
                Logout
              </button>
            </nav>
          </div>
          <label htmlFor="menuboton" className="despliegue">
            <IoIosArrowForward className="flecha" />
          </label>
          <div className="capa"></div>
        </>
      ) : null}
    </Fragment>
  );
};

export default Barralateral;
