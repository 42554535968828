import React, { Fragment } from "react";
import "../Styles/Masvendidas.css";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import Posts from "./Posts";

function Masvendidas(props) {
  var posicion = 0;
  var anchoDePantalla = document.documentElement.scrollWidth;

  const moverIzquierda = () => {
    const objeto = document.getElementById("bloque");
    if (posicion <= 0) {
      return false;
    } else {
      posicion = posicion - 200;
      objeto.scrollTo({
        top: 0,
        left: posicion,
        behavior: "smooth",
      });
    }
  };
  const moverDerecha = () => {
    const objeto = document.getElementById("bloque");
    if (posicion >= 9600) {
      return false;
    } else {
      posicion = posicion + 200;
      objeto.scrollTo({
        top: 0,
        left: posicion,
        behavior: "smooth",
      });
    }
  };

  return (
    <Fragment>
      {props.products !== undefined ? (
        props.products.length > 0 ? (
          <>
            <h2 className="titulo_populares">Lo mas popular</h2>
            <div className="mostsale_items" id="bloque">
              <button className="izquierda" onClick={moverIzquierda}>
                <IoIosArrowBack className="arrowback" />
              </button>
              {props.products.map((i) =>
                i.downloads >= 7 ? (
                  <Posts
                    info={i}
                    own={false}
                    user={props.user}
                    likes={props.likes.includes(i.imageid) ? true : false}
                  />
                ) : null
              )}
              <button className="derecha" onClick={moverDerecha}>
                <IoIosArrowForward className="arrowforward" />
              </button>
            </div>
          </>
        ) : null
      ) : null}
    </Fragment>
  );
}

export default Masvendidas;
