import React from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import "../Styles/checkTerms.css";

const useTerminosCheck = () => {
  //var checkInput = false;
  const [checkInput, setcheckInput] = useState(false);

  const CheckBoxTerms = ({ typeCheck }) => {
    const check = () => {
      var check = document.getElementById(typeCheck ? "checkeoTwo" : "checkeo");
      setcheckInput(check.checked);
    };
    return (
      <div className="verificarTerminos">
        {typeCheck ? (
          <input
            type="checkbox"
            className="cajaCheck"
            id="checkeoTwo"
            onChange={() => {
              check();
            }}
          />
        ) : (
          <input
            type="checkbox"
            className="cajaCheck"
            id="checkeo"
            onChange={() => {
              check();
            }}
          />
        )}
        <p>
          He leido y acepto los{" "}
          <Link className="a" to="/terminos" target="_blank">
            Terminos y condiciones
          </Link>{" "}
          y las{" "}
          <Link className="a" to="/privacidad" target="_blank">
            Políticas de privacidad
          </Link>{" "}
          de la pagina.
        </p>
      </div>
    );
  };

  return [CheckBoxTerms, checkInput];
};

export default useTerminosCheck;
