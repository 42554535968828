import { db } from "./firebase";

const initialproducts = {
  products: [],
  loading: false,
};

const OBTENER_PRODUCTOS = "OBTENER_PRODUCTOS";
const LOADING = "LOADING";

export default function reducerProducts(state = initialproducts, action) {
  switch (action.type) {
    case LOADING:
      return { ...state, loading: true };
    case OBTENER_PRODUCTOS:
      return { ...state, loading: false, products: action.payload };
    default:
      return state;
  }
}

export const obtenerProducts =
  (identificador) => async (dispatch, getState) => {
    dispatch({
      type: LOADING,
    });
    try {
      const data = await db.collection(identificador).get();
      const userPosts = data.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      dispatch({
        type: OBTENER_PRODUCTOS,
        payload: userPosts,
      });
    } catch (err) {
      console.log(err);
    }
  };
