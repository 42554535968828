import Swal from "sweetalert2";
import { loadanimation } from "./Cargas";
import { auth, db } from "./firebase";
import moment from "moment";
import firebase from "firebase/app";

const initialState = {
    likes: [],
};

const BUSCAR = "BUSCAR";

export default function linkeReducer(state = initialState, action) {
    switch (action.type) {
        case BUSCAR:
            return {...state, likes: action.payload };
        default:
            return state;
    }
}

export const buscarlikes = () => async(dispatch) => {
    const user = auth.currentUser;
    try {
        if (user) {
            const likesfotos = await db
                .collection(user.uid)
                .doc("likes")
                .collection(`like${user.uid}`)
                .get();
            const arraylike = likesfotos.docs.map((doc) => doc.id);
            dispatch({
                type: BUSCAR,
                payload: arraylike,
            });
        }
    } catch (error) {
        console.log(error);
    }
};

export const addlike = (id) => async(dispatch) => {
    const user = auth.currentUser;
    const hoy = moment();
    try {
        loadanimation();
        const ref = await db.collection("AllProducts").doc(id).get();
        const original = await ref.data().autor;
        if (ref.exists) {
            await db
                .collection("AllProducts")
                .doc(id)
                .update({
                    likes: (await ref.data().likes) + 1,
                });
            await db
                .collection(original)
                .doc(id)
                .update({
                    likes: (await ref.data().likes) + 1,
                });
            db.collection(user.uid)
                .doc("likes")
                .collection(`like${user.uid}`)
                .doc(id)
                .set({ liked: true });
            dispatch(buscarlikes());
            const historiahoy = db
                .collection(user.uid)
                .doc("history")
                .collection("historial")
                .doc(hoy.format("MM-DD-YYYY"));
            const historiahoyver = await historiahoy.get();
            if (historiahoyver.exists) {
                await historiahoy.update({
                    historial: firebase.firestore.FieldValue.arrayUnion({
                        mensaje: `Le diste like a ${ref.data().title}`,
                    }),
                });
            } else {
                await db
                    .collection(user.uid)
                    .doc("history")
                    .collection("historial")
                    .doc(hoy.format("MM-DD-YYYY"))
                    .set({
                        historial: [{ mensaje: `Le diste like a ${ref.data().title}` }],
                    });
            }

            const historiahoyautor = db
                .collection(ref.data().autor)
                .doc("history")
                .collection("historial")
                .doc(hoy.format("MM-DD-YYYY"));
            const historiahoyautorver = await historiahoyautor.get();
            if (historiahoyautorver.exists) {
                await historiahoyautor.update({
                    historial: firebase.firestore.FieldValue.arrayUnion({
                        mensaje: `${ref.data().title} obtuvo un like`,
                    }),
                });
            } else {
                await db
                    .collection(ref.data().autor)
                    .doc("history")
                    .collection("historial")
                    .doc(hoy.format("MM-DD-YYYY"))
                    .set({
                        historial: [{ mensaje: `${ref.data().title} obtuvo un like` }],
                    });
            }
        } else {
            console.log("");
        }
    } catch (error) {
        console.log(error);
    } finally {
        Swal.close();
    }
};

export const deletelike = (id) => async(dispatch) => {
    const user = auth.currentUser;
    try {
        loadanimation();
        const ref = await db.collection("AllProducts").doc(id).get();
        const original = await ref.data().autor;
        if (ref.exists) {
            await db
                .collection("AllProducts")
                .doc(id)
                .update({
                    likes: (await ref.data().likes) - 1,
                });
            await db
                .collection(original)
                .doc(id)
                .update({
                    likes: (await ref.data().likes) - 1,
                });
            await db
                .collection("likes")
                .doc("userliked")
                .collection(user.uid)
                .doc(id)
                .delete();
            await db
                .collection(user.uid)
                .doc("likes")
                .collection(`like${user.uid}`)
                .doc(id)
                .delete();
            dispatch(buscarlikes());
            Swal.close();
        } else {
            console.log("");
        }
    } catch (error) {
        console.log(error);
    }
};