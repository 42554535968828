import Swal from "sweetalert2";
import { loadanimation } from "./Cargas";
import { db } from "./firebase";

const initialState = {
  headerdato: "",
};

const OBTENER_HEADER_DATA = "OBTENER_HEADER_DATA";

export default function headerdatosReducer(state = initialState, action) {
  switch (action.type) {
    case OBTENER_HEADER_DATA:
      return { ...state, headerdato: action.payload };
    default:
      return state;
  }
}

export const headerdata = (current) => async (dispatch) => {
  try {
    loadanimation();
    const usuario = await db.collection("users").doc(current).get();
    await dispatch({
      type: OBTENER_HEADER_DATA,
      payload: usuario.data().username,
    });
    Swal.close();
  } catch (error) {
    console.log(error);
  }
};
