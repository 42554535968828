import React, { useEffect } from "react";
import { useState } from "react";
import { Fragment } from "react";
import { Link, useParams } from "react-router-dom";
import Circle from "./Circle";
import "../Styles/historial.css";
import { historial } from "../Redux/historial";
import { useDispatch, useSelector } from "react-redux";

function Historial(props) {
  const [loading, setLoading] = useState(true);
  const usuario = useParams();
  const dispatch = useDispatch();
  const historias = useSelector((store) => store.historial.historia);

  useEffect(() => {
    document.title = "Mi historial";
    if (props.user) {
      const usuariodata = JSON.parse(sessionStorage.getItem("currentuser"));
      if (usuariodata !== undefined && usuariodata !== null) {
        if (usuariodata.data.username !== usuario.usueractual) {
          window.location.replace("/");
        } else {
          dispatch(historial());
          if (
            historias !== undefined &&
            historias !== null &&
            historias !== []
          ) {
            setLoading(false);
          }
        }
      }
    }
  }, [props.user]);

  return (
    <Fragment>
      {loading ? (
        <Circle />
      ) : (
        <div className="historial">
          <h4>Historial</h4>
          <div className="contenedorhistorial">
            {historias !== undefined &&
            historias !== null &&
            historias !== [] ? (
              historias.map((i) => (
                <>
                  <h5 key={historias.indexOf(i)} className="fecha">
                    {i.id}
                  </h5>
                  <div className="eventos">
                    {i.historial.map((j) => (
                      <h6 key={i.historial.indexOf(j)}>{j.mensaje}</h6>
                    ))}
                  </div>
                </>
              ))
            ) : (
              <h4>Se produjo un error</h4>
            )}
          </div>
        </div>
      )}
    </Fragment>
  );
}

export default Historial;
