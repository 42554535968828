import React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import { obtenerCompras } from "../Redux/compras";
import perfilImagen from "../Imagenes/perfil.jpg";
import "../Styles/compras.css";
import { useState } from "react";

const Compras = ({ rol }) => {
  const [loadingRol, setLoadingRol] = useState(true);
  const [rolAdmin, setRolAdmin] = useState(false);
  const dispatch = useDispatch();

  const sales = useSelector((store) => store.compras);

  const { compras, loading, error } = sales;

  useEffect(() => {
    document.title = "Admin - Compras";
  }, []);

  useEffect(() => {
    dispatch(obtenerCompras());
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (rol.rol.admin) {
      setLoadingRol(false);
      setRolAdmin(true);
    } else {
      setLoadingRol(false);
      setRolAdmin(false);
    }
  }, [rol.rol]);

  if (loadingRol) return <h1>Loading...</h1>;

  return rolAdmin ? (
    <div className="comprasContainer">
      <h4>Compras</h4>
      <table className="comprasList">
        <tr className="encabezado">
          <th></th>
          <th>Usuario</th>
          <th>Tipo</th>
          <th>Cantidad Comprada</th>
          <th></th>
        </tr>

        {loading ? (
          <h1>Loading...</h1>
        ) : (
          compras.map((compra) => (
            <tr className="compraItem">
              <td>
                <img
                  className="perfilImage"
                  src={compra.profile ? `${compra.profile}` : `${perfilImagen}`}
                  alt="foto de perfil"
                />
              </td>
              <td>
                <Link to={`/${compra.username}`} className="userName">
                  {compra.username}
                </Link>
              </td>
              <td>
                <p className="userRol">{compra.rol}</p>
              </td>
              <td>
                <p className="cantidadComprado">$ {compra.buyed}.00</p>
              </td>
              <td>
                <Link to="/#" className="detallesCompras">
                  Ver registro
                </Link>
              </td>
            </tr>
          ))
        )}
      </table>
    </div>
  ) : (
    <Redirect to="/" />
  );
};

export default Compras;
