import React, { useEffect } from "react";
import { useState } from "react";
import { Fragment } from "react";
import Flag from "react-world-flags";
import { useDispatch, useSelector } from "react-redux";
import { obtendatos } from "../Redux/Login";
import { TiCameraOutline } from "react-icons/ti";
import { FiAlertTriangle } from "react-icons/fi";
import "../Styles/Ajustes.css";
import { actualizar } from "../Redux/actualizadatos";
import { alerta } from "../Redux/Cargas";
import Editfoto from "./Editfoto";
import { MdTagFaces } from "react-icons/md";
import Circle from "./Circle";
import { solicitarverificacion } from "../Redux/allUsers";

import { firebase } from "../Redux/firebase";

function Ajustes(props) {
  const dataProvider = firebase.auth().currentUser.providerData[0].providerId;
  const dispatch = useDispatch();
  const [newdata, setNewdata] = useState({});
  const [edicion, setEdicion] = useState(false);
  const [banderanueva, setBanderanueva] = useState(false);
  const usuariodat = useSelector((store) => store.login.usuariodata);
  const loading = useSelector((store) => store.login.loading);
  const datos = async () => {
    try {
      dispatch(obtendatos(props.user.uid));
    } catch (error) {
      console.log(error);
    }
  };

  if (usuariodat === null || usuariodat === undefined) {
    datos();
  }

  useEffect(() => {
    document.title = "Ajustes";
    if (props.user) {
      datos();
    } else {
      return null;
    }
    //eslint-disable-next-line
  }, [props.user]);

  const actualizardato = (e) => {
    document.getElementById("enviar").disabled = false;
    document.getElementById("enviar2").disabled = false;
    if (e.target.name === "country") {
      setBanderanueva(true);
      setNewdata({
        ...newdata,
        [e.target.name]: e.target.value,
      });
    }
    setNewdata({
      ...newdata,
      [e.target.name]: e.target.value,
    });
  };

  const enviar = (e) => {
    e.preventDefault();
    if (
      newdata.name === "" ||
      newdata.username === "" ||
      newdata.correo === "" ||
      newdata.country === "" ||
      newdata.direccion === "" ||
      newdata.payemail === ""
    ) {
      alerta("No puedes pasar campos vacios");
    } else {
      if (
        newdata.password !== undefined &&
        newdata.newpassword !== undefined &&
        newdata.newpasswordver !== undefined
      ) {
        if (newdata.password === newdata.newpassword) {
          alerta("La nueva contraseña no puede ser igual a la actual");
        } else {
          if (newdata.newpassword === newdata.newpasswordver) {
            actualizar(newdata, props.user, usuariodat.data.username);
          } else {
            alerta("La nueva contraseña no coincide");
          }
        }
      } else {
        actualizar(newdata, props.user, usuariodat.data.username);
      }
    }
  };

  const cambio = {
    activar: () => {
      setEdicion(true);
    },
    desactivar: () => {
      setEdicion(false);
    },
  };

  const seleccionarArchivo = (imagen) => {
    if (
      imagen.target.files[0] !== null &&
      imagen.target.files[0] !== undefined
    ) {
      dispatch(
        solicitarverificacion(
          props.user.uid,
          imagen.target.files[0],
          usuariodat.data.email,
          usuariodat.data.username
        )
      );
    }
  };

  return (
    <Fragment>
      <div className="contenido">
        {loading ? (
          <h2>Loading...</h2>
        ) : (
          <>
            {props.user &&
            usuariodat.data !== undefined &&
            usuariodat.data !== null ? (
              <>
                <h4 className="maintitulo">Informacion de cuenta</h4>
                <div className="perfilinfo">
                  <div className="cambiarfoto">
                    {edicion ? (
                      <Editfoto
                        cerrar={cambio}
                        foto={usuariodat.data.profile}
                        info={usuariodat.data}
                      />
                    ) : null}
                    <button className="perfilbuton" onClick={cambio.activar}>
                      {usuariodat.data.profile === null ? (
                        <MdTagFaces className="imagen2" />
                      ) : (
                        <img
                          src={usuariodat.data.profile}
                          alt=""
                          className="fotodeperfil"
                        />
                      )}
                    </button>
                    <button onClick={cambio.activar}>
                      Cambiar foto de parfil
                    </button>
                  </div>
                  <form className="valores" onSubmit={enviar}>
                    <label htmlFor="name">Nombre</label>
                    <input
                      type="text"
                      id="name"
                      defaultValue={usuariodat.data.name}
                      name="name"
                      onChange={actualizardato}
                    />
                    <label htmlFor="user">Usuario</label>
                    <input
                      type="text"
                      id="user"
                      defaultValue={usuariodat.data.username}
                      name="username"
                      onChange={actualizardato}
                    />
                    <p>
                      <FiAlertTriangle /> NO recomendamos cambiar el nombre de
                      usuario frecuentemente
                    </p>
                    <input
                      type="submit"
                      value="Actualizar"
                      disabled
                      id="enviar"
                    />
                  </form>
                </div>
                <form className="personal" onSubmit={enviar}>
                  <h4>Informacion personal</h4>
                  <label htmlFor="mail">Correo</label>
                  <input
                    type="email"
                    id="mail"
                    defaultValue={usuariodat.data.email}
                    name="email"
                    onChange={actualizardato}
                  />

                  <Flag
                    code={usuariodat.data.country}
                    height="36"
                    className="flag"
                  />
                  <div className="paiscontainer">
                    <select
                      name="country"
                      id="pais"
                      onChange={actualizardato}
                      defaultValue={usuariodat.data.country}>
                      <option value={usuariodat.data.country}>
                        {usuariodat.data.country}
                      </option>
                      <option value="ad">AD</option>
                      <option value="ae">AE</option>
                      <option value="af">AF</option>
                      <option value="ag">AG</option>
                      <option value="ai">AI</option>
                      <option value="al">AL</option>
                      <option value="am">AM</option>
                      <option value="an">AN</option>
                      <option value="ao">AO</option>
                      <option value="aq">AQ</option>
                      <option value="ar">AR</option>
                      <option value="as">AS</option>
                      <option value="at">AT</option>
                      <option value="au">AU</option>
                      <option value="aw">AW</option>
                      <option value="ax">AX</option>
                      <option value="az">AZ</option>
                      <option value="ba">BA</option>
                      <option value="bb">BB</option>
                      <option value="bd">BD</option>
                      <option value="be">BE</option>
                      <option value="bf">BF</option>
                      <option value="bg">BG</option>
                      <option value="bh">BH</option>
                      <option value="bi">BI</option>
                      <option value="bj">BJ</option>
                      <option value="bl">BL</option>
                      <option value="bm">BM</option>
                      <option value="bn">BN</option>
                      <option value="bo">BO</option>
                      <option value="bq">BQ</option>
                      <option value="br">BR</option>
                      <option value="bs">BS</option>
                      <option value="bt">BT</option>
                      <option value="bv">BV</option>
                      <option value="bw">BW</option>
                      <option value="by">BY</option>
                      <option value="bz">BZ</option>
                      <option value="ca">CA</option>
                      <option value="cc">CC</option>
                      <option value="cd">CD</option>
                      <option value="cf">CF</option>
                      <option value="cg">CG</option>
                      <option value="ch">CH</option>
                      <option value="ci">CI</option>
                      <option value="ck">CK</option>
                      <option value="cl">CL</option>
                      <option value="cm">CM</option>
                      <option value="cn">CN</option>
                      <option value="co">CO</option>
                      <option value="cr">CR</option>
                      <option value="cu">CU</option>
                      <option value="cv">CV</option>
                      <option value="cw">CW</option>
                      <option value="cx">CX</option>
                      <option value="cy">CY</option>
                      <option value="cz">CZ</option>
                      <option value="de">DE</option>
                      <option value="dj">DJ</option>
                      <option value="dk">DK</option>
                      <option value="dm">DM</option>
                      <option value="do">DO</option>
                      <option value="dz">DZ</option>
                      <option value="ec">EC</option>
                      <option value="ee">EE</option>
                      <option value="eg">EG</option>
                      <option value="eh">EH</option>
                      <option value="er">ER</option>
                      <option value="es">ES</option>
                      <option value="et">ET</option>
                      <option value="eu">EU</option>
                      <option value="fi">FI</option>
                      <option value="fj">FJ</option>
                      <option value="fk">FK</option>
                      <option value="fm">FM</option>
                      <option value="fo">FO</option>
                      <option value="fr">FR</option>
                      <option value="ga">GA</option>
                      <option value="gb">GB</option>
                      <option value="gd">GD</option>
                      <option value="ge">GE</option>
                      <option value="gf">GF</option>
                      <option value="gg">GG</option>
                      <option value="gh">GH</option>
                      <option value="gi">GI</option>
                      <option value="gl">GL</option>
                      <option value="gm">GM</option>
                      <option value="gn">GN</option>
                      <option value="gp">GP</option>
                      <option value="gq">GQ</option>
                      <option value="gr">GR</option>
                      <option value="gs">GS</option>
                      <option value="gt">GT</option>
                      <option value="gu">GU</option>
                      <option value="gw">GW</option>
                      <option value="gy">GY</option>
                      <option value="hk">HK</option>
                      <option value="hm">HM</option>
                      <option value="hn">HN</option>
                      <option value="hr">HR</option>
                      <option value="ht">HT</option>
                      <option value="hu">HU</option>
                      <option value="id">ID</option>
                      <option value="ie">IE</option>
                      <option value="il">IL</option>
                      <option value="im">IM</option>
                      <option value="in">IN</option>
                      <option value="io">IO</option>
                      <option value="iq">IQ</option>
                      <option value="ir">IR</option>
                      <option value="is">IS</option>
                      <option value="it">IT</option>
                      <option value="je">JE</option>
                      <option value="jm">JM</option>
                      <option value="jo">JO</option>
                      <option value="jp">JP</option>
                      <option value="ke">KE</option>
                      <option value="kg">KG</option>
                      <option value="kh">KH</option>
                      <option value="ki">KI</option>
                      <option value="km">KM</option>
                      <option value="kn">KN</option>
                      <option value="kr">KR</option>
                      <option value="kw">KW</option>
                      <option value="ky">KY</option>
                      <option value="kz">KZ</option>
                      <option value="la">LA</option>
                      <option value="lb">LB</option>
                      <option value="lc">LC</option>
                      <option value="li">LI</option>
                      <option value="lk">LK</option>
                      <option value="lr">LR</option>
                      <option value="ls">LS</option>
                      <option value="lt">LT</option>
                      <option value="lu">LU</option>
                      <option value="lv">LV</option>
                      <option value="ly">LY</option>
                      <option value="ma">MA</option>
                      <option value="mc">MC</option>
                      <option value="md">MD</option>
                      <option value="me">ME</option>
                      <option value="mf">MF</option>
                      <option value="mg">MG</option>
                      <option value="mh">MH</option>
                      <option value="mk">MK</option>
                      <option value="ml">ML</option>
                      <option value="mm">MM</option>
                      <option value="mn">MN</option>
                      <option value="mo">MO</option>
                      <option value="mp">MP</option>
                      <option value="mq">MQ</option>
                      <option value="mr">MR</option>
                      <option value="ms">MS</option>
                      <option value="mt">MT</option>
                      <option value="mu">MU</option>
                      <option value="mv">MV</option>
                      <option value="mw">MW</option>
                      <option value="mx">MX</option>
                      <option value="my">MY</option>
                      <option value="mz">MZ</option>
                      <option value="na">NA</option>
                      <option value="nc">NC</option>
                      <option value="ne">NE</option>
                      <option value="nf">NF</option>
                      <option value="ng">NG</option>
                      <option value="ni">NI</option>
                      <option value="nl">NL</option>
                      <option value="no">NO</option>
                      <option value="np">NP</option>
                      <option value="nr">NR</option>
                      <option value="nu">NU</option>
                      <option value="nz">NZ</option>
                      <option value="om">OM</option>
                      <option value="pa">PA</option>
                      <option value="pe">PE</option>
                      <option value="pf">PF</option>
                      <option value="pg">PG</option>
                      <option value="ph">PH</option>
                      <option value="pk">PK</option>
                      <option value="pl">PL</option>
                      <option value="pm">PM</option>
                      <option value="pn">PN</option>
                      <option value="pr">PR</option>
                      <option value="ps">PS</option>
                      <option value="pt">PT</option>
                      <option value="pw">PW</option>
                      <option value="py">PY</option>
                      <option value="qa">QA</option>
                      <option value="re">RE</option>
                      <option value="ro">RO</option>
                      <option value="rs">RS</option>
                      <option value="ru">RU</option>
                      <option value="rw">RW</option>
                      <option value="sa">SA</option>
                      <option value="sb">SB</option>
                      <option value="sc">SC</option>
                      <option value="sd">SD</option>
                      <option value="se">SE</option>
                      <option value="sg">SG</option>
                      <option value="sh">SH</option>
                      <option value="si">SI</option>
                      <option value="sj">SJ</option>
                      <option value="sk">SK</option>
                      <option value="sl">SL</option>
                      <option value="sm">SM</option>
                      <option value="sn">SN</option>
                      <option value="so">SO</option>
                      <option value="sr">SR</option>
                      <option value="ss">SS</option>
                      <option value="st">ST</option>
                      <option value="sv">SV</option>
                      <option value="sx">SX</option>
                      <option value="sy">SY</option>
                      <option value="sz">SZ</option>
                      <option value="tc">TC</option>
                      <option value="td">TD</option>
                      <option value="tf">TF</option>
                      <option value="tg">TG</option>
                      <option value="th">TH</option>
                      <option value="tj">TJ</option>
                      <option value="tk">TK</option>
                      <option value="tl">TL</option>
                      <option value="tm">TM</option>
                      <option value="tn">TN</option>
                      <option value="to">TO</option>
                      <option value="tr">TR</option>
                      <option value="tt">TT</option>
                      <option value="tv">TV</option>
                      <option value="tw">TW</option>
                      <option value="tz">TZ</option>
                      <option value="ua">UA</option>
                      <option value="ug">UG</option>
                      <option value="um">UM</option>
                      <option value="us">US</option>
                      <option value="uy">UY</option>
                      <option value="uz">UZ</option>
                      <option value="va">VA</option>
                      <option value="vc">VC</option>
                      <option value="ve">VE</option>
                      <option value="vg">VG</option>
                      <option value="vi">VI</option>
                      <option value="vn">VN</option>
                      <option value="vu">VU</option>
                      <option value="wf">WF</option>
                      <option value="ws">WS</option>
                      <option value="xk">XK</option>
                      <option value="ye">YE</option>
                      <option value="yt">YT</option>
                      <option value="za">ZA</option>
                      <option value="zm">ZM</option>
                      <option value="zw">ZW</option>
                    </select>
                    {banderanueva ? (
                      <Flag
                        code={newdata.country}
                        height="36"
                        className="flag"
                      />
                    ) : null}
                  </div>
                  {usuariodat.data.rol !== "regular" ? (
                    <>
                      <label htmlFor="mail">Direcion</label>
                      <input
                        type="text"
                        id="direct"
                        defaultValue={usuariodat.data.direction}
                        name="direction"
                        onChange={actualizardato}
                      />
                      <label htmlFor="mail">Correo de pago</label>
                      <input
                        type="email"
                        id="direct"
                        defaultValue={usuariodat.data.payemail}
                        name="payemail"
                        onChange={actualizardato}
                      />
                    </>
                  ) : null}
                  <input
                    type="submit"
                    value="Actualizar"
                    disabled
                    id="enviar2"
                  />
                </form>

                {dataProvider === "facebook.com" || "google.com" ? (
                  console.log("")
                ) : (
                  <div className="otrasOpciones">
                    <h4>Cambiar contraseña</h4>
                    <form className="cambiocontrasena">
                      <label htmlFor="actualpas">Contraseña actual</label>
                      <input
                        type="password"
                        id="actualpas"
                        className="pas"
                        name="password"
                        onChange={actualizardato}
                      />
                      <label htmlFor="newpas">Nueva contraseña</label>
                      <input
                        type="password"
                        id="newpas"
                        className="pas"
                        name="newpassword"
                        onChange={actualizardato}
                      />
                      <label htmlFor="newpascon">Contraseña actual</label>
                      <input
                        type="password"
                        id="newpascon"
                        className="pas"
                        name="newpasswordver"
                        onChange={actualizardato}
                      />
                    </form>
                  </div>
                )}

                {/* */}
                {usuariodat.data.rol === "autor" &&
                usuariodat.data.verified === false ? (
                  <div className="solicitarverificacion">
                    <h4>Solicitar verificacion</h4>
                    <p>
                      Para verificar tu cuenta envianos una selfie con tu cedula
                      (ESTA INFORMACION SERA ACCESIBLE SOLO PARA SUBLIMAT Y
                      DISEÑOS BELLA)
                    </p>
                    <input
                      type="file"
                      accept="image/jpg, image/jpeg"
                      id="cedula"
                      onChange={(e) => seleccionarArchivo(e)}
                    />
                    <label htmlFor="cedula" className="subircedula">
                      <TiCameraOutline />
                    </label>
                  </div>
                ) : null}
              </>
            ) : (
              <Circle />
            )}
          </>
        )}
      </div>
    </Fragment>
  );
}

export default Ajustes;
