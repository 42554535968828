import Swal from "sweetalert2";

export const loadanimation = (mensaje) => {
  if (mensaje) {
    Swal.fire({
      title: mensaje,
      width: "90%",
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
  } else {
    Swal.fire({
      title: "Porfavor Espere!",
      width: "90%",
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
  }
};

export const erroranimation = (mensaje) => {
  if (mensaje) {
    Swal.fire({
      icon: "error",
      title: "Oops...",
      text: mensaje,
    });
  } else {
    Swal.fire({
      icon: "error",
      title: "Oops...",
      text: "Algo salio mal",
    });
  }
};
export const correctloaded = (mensaje) => {
  Swal.fire({
    position: "center",
    icon: "success",
    title: mensaje,
    showConfirmButton: false,
    timer: 3000,
  });
};

export const alerta = (mensaje) => {
  Swal.fire({
    title: "CUIDADO!!",
    text: mensaje,
    icon: "warning",
    timer: "1500",
  });
};
