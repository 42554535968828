import { db, auth, functions, firebase, googleAuthProvider, facebookAuthProvider } from "./firebase";

//import { db, auth, functions } from "./firebase";
//import firebase from 'firebase/app';

import { loadanimation, erroranimation, correctloaded, alerta } from "./Cargas";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";
import { obtendatos } from "./Login";

const id = uuidv4();

/* const autor = async(identificador, email, pass) => {
    loadanimation();
    const agregar = functions.httpsCallable("crearAutor");

    await agregar({ email: email }).then((res) => {
        if (res.data.error) {
            console.log("no tienes permisos");
            return;
        }
    });
    await db.collection("users").doc(identificador).update({ rol: "autor" });
    await auth.signInWithEmailAndPassword(email, pass);
    window.location.replace("/");
};
 */


//aqui se hacen las pruebas de login con google 

const initianStateUser = {
    usuariodata: [],
};
const LOGIN = 'AUTH_LOGIN'

export default function reducerGoogleLogin(state = initianStateUser, action) {
    switch (action.type) {
        case LOGIN:
            return {...state, usuariodata: action.payload };
        default:
            return state;
    }
}

export const startGoogleLogin = () => {
    var values = [],
        keys = Object.keys(localStorage),
        i = keys.length;

    while (i--) {
        if (keys[i].substring(0, 7) === "/basket") {
            values.push(localStorage.getItem(keys[i]));
        }
    }
    return async(dispatch) => {
        //loadanimation();
        const hoy = moment();
        try {
            const res = await firebase.auth().signInWithPopup(googleAuthProvider)

            await firebase.auth().currentUser.providerData[0].providerId;


            const userData = await db.doc(`users/${res.user.uid}`).get()
            if (userData.exists) {
                await dispatch(obtendatos(res.user.uid))
                    //window.location.replace('/')
                if (values.length > 0) {
                    const carrito = db.collection(res.user.uid).doc("Basket");
                    values.forEach(async(item, index) => {
                        let idItem = item.replaceAll(`"`, "")
                        await carrito.update({
                            carrito: firebase.firestore.FieldValue.arrayUnion(idItem)
                        })
                        localStorage.removeItem(`/basket${idItem}`)
                        if (index + 1 === values.length) {
                            await dispatch(obtendatos(res.user.uid))
                            window.location.reload();
                        }
                    });
                } else {
                    await dispatch(obtendatos(res.user.uid))
                    window.location.reload();
                }
            } else {
                const regex = res.user.displayName.replaceAll(' ', '_');
                await db.collection("users").doc(res.user.uid).set({
                    unicid: res.user.uid,
                    name: res.user.displayName,
                    username: `${regex}${id}`,
                    email: res.user.email,
                    profile: null,
                    rol: "regular",
                    buyed: 0
                })
                await db.collection("usernames").doc(`${regex}${id}`).set({
                    id: res.user.uid,
                });
                await db.collection(res.user.uid).doc("Basket").set({ carrito: [] });
                await db
                    .collection(res.user.uid)
                    .doc("history")
                    .collection("historial")
                    .doc(hoy.format("MM-DD-YYYY"))
                    .set({ historial: [{ mensaje: "Te registraste" }] });
                correctloaded("Ya puedes Iniciar sesion");
                //window.location.replace("/");
                if (values.length > 0) {
                    const carrito = db.collection(res.user.uid).doc("Basket");
                    values.forEach(async(item, index) => {
                        let idItem = item.replaceAll(`"`, "")
                        await carrito.update({
                            carrito: firebase.firestore.FieldValue.arrayUnion(idItem)
                        })
                        localStorage.removeItem(`/basket${idItem}`)
                        if (index + 1 === values.length) {
                            await dispatch(obtendatos(res.user.uid))
                            window.location.reload();
                        }
                    });
                } else {
                    await dispatch(obtendatos(res.user.uid))
                    window.location.reload();
                }
            }

        } catch (error) {
            return error
        }
    }
}

// hasta aqui llega el login con google

//aqui empieza el login con facebook

const LOGIN_FACEBOOK = 'AUTH_LOGIN_FACEBOOK'

export function reducerFacebookLogin(state = initianStateUser, action) {
    switch (action.type) {
        case LOGIN_FACEBOOK:
            return {...state, usuariodata: action.payload };
        default:
            return state;
    }
}

export const startFacebookLogin = () => {
    var values = [],
        keys = Object.keys(localStorage),
        i = keys.length;

    while (i--) {
        if (keys[i].substring(0, 7) === "/basket") {
            values.push(localStorage.getItem(keys[i]));
        }
    }

    return async(dispatch) => {
        //loadanimation();
        const hoy = moment();
        try {
            const res = await auth.signInWithPopup(facebookAuthProvider)
                //await firebase.auth().currentUser.providerData[0].providerId;


            const userData = await db.doc(`users/${res.user.uid}`).get();

            if (userData.exists) {
                await dispatch(obtendatos(res.user.uid))
                    //window.location.replace('/')
                if (values.length > 0) {
                    const carrito = db.collection(res.user.uid).doc("Basket");
                    values.forEach(async(item, index) => {
                        let idItem = item.replaceAll(`"`, "")
                        await carrito.update({
                            carrito: firebase.firestore.FieldValue.arrayUnion(idItem)
                        })
                        localStorage.removeItem(`/basket${idItem}`)
                        if (index + 1 === values.length) {
                            await dispatch(obtendatos(res.user.uid))
                            window.location.reload();
                        }
                    });
                } else {
                    await dispatch(obtendatos(res.user.uid))
                    window.location.reload();
                }
            } else {
                const regex = res.user.displayName.replaceAll(' ', '_');
                await db.collection("users").doc(res.user.uid).set({
                    unicid: res.user.uid,
                    name: res.user.displayName,
                    username: `${regex}${id}`,
                    email: res.user.email,
                    profile: null,
                    rol: "regular",
                    buyed: 0

                })


                await db.collection("usernames").doc(`${regex}${id}`).set({
                    id: res.user.uid,
                });
                await db.collection(res.user.uid).doc("Basket").set({ carrito: [] });
                await db
                    .collection(res.user.uid)
                    .doc("history")
                    .collection("historial")
                    .doc(hoy.format("MM-DD-YYYY"))
                    .set({ historial: [{ mensaje: "Te registraste" }] });
                correctloaded("Ya puedes Iniciar sesion");
                //window.location.replace("/");
                if (values.length > 0) {
                    const carrito = db.collection(res.user.uid).doc("Basket");
                    values.forEach(async(item, index) => {
                        let idItem = item.replaceAll(`"`, "")
                        await carrito.update({
                            carrito: firebase.firestore.FieldValue.arrayUnion(idItem)
                        })
                        localStorage.removeItem(`/basket${idItem}`)
                        if (index + 1 === values.length) {
                            await dispatch(obtendatos(res.user.uid))
                            window.location.reload();
                        }
                    });
                } else {
                    await dispatch(obtendatos(res.user.uid))
                    window.location.reload();
                }
            }



        } catch (error) {
            return error
        }
    }

}






//aqui termina el login con facebook

const autor = async(identificador, email, pass) => {

    var valuesAutor = [],
        keys = Object.keys(localStorage),
        i = keys.length;

    while (i--) {
        if (keys[i].substring(0, 7) === "/basket") {
            valuesAutor.push(localStorage.getItem(keys[i]));
        }
    }
    loadanimation();
    const agregar = functions.httpsCallable("crearAutor");

    await agregar({ email: email }).then((res) => {
        if (res.data.error) {
            console.log("s");
            return;
        }
    });
    await db.collection("users").doc(identificador).update({ rol: "autor" });
    const user = await auth.signInWithEmailAndPassword(email, pass);
    if (valuesAutor.length > 0) {
        const carrito = db.collection(user.user.uid).doc("Basket");
        valuesAutor.forEach(async(item, index) => {
            let idItem = item.replaceAll(`"`, "")
                //console.log(idItem);
            await carrito.update({
                carrito: firebase.firestore.FieldValue.arrayUnion(idItem)
            })
            localStorage.removeItem(`/basket${idItem}`)
            if (index + 1 === valuesAutor.length) {
                window.location.replace("/");
            }
        });
    } else {
        window.location.replace("/");
    }
};

export const registrarUsuario = (datos) => async() => {
    var values = [],
        keys = Object.keys(localStorage),
        i = keys.length;

    while (i--) {
        if (keys[i].substring(0, 7) === "/basket") {
            values.push(localStorage.getItem(keys[i]));
        }
    }

    try {
        loadanimation();
        const hoy = moment();
        //Llamar a los nombre de usuario existentes
        const data = await db.collection("usernames").doc(datos.usuario).get();

        //Verificar si el nombre de usuario ya existe
        if (data.exists) {
            //Si existe, mandar error
            erroranimation("Nombre de Usuario ya en uso");
        } else {
            //Si no existe, crea el usuario
            //Si es vendedor
            if (datos.tipo === "vendedor") {
                if (datos.edad > 18) {
                    const res = await auth.createUserWithEmailAndPassword(
                        datos.correo,
                        datos.contraseña
                    );
                    await db.collection("users").doc(res.user.uid).set({
                        name: datos.nombre,
                        username: datos.usuario,
                        unicid: res.user.uid,
                        email: res.user.email,
                        country: datos.pais,
                        rol: datos.tipo,
                        direction: datos.direction,
                        age: datos.edad,
                        profile: null,
                        verified: false,
                        gained: 0,
                        buyed: 0,
                        payemail: datos.payemail,
                    });
                    await db.collection("usernames").doc(datos.usuario).set({
                        id: res.user.uid,
                    });
                    await db
                        .collection("ventas")
                        .doc(res.user.uid)
                        .set({
                            referenciausuario: `users/${res.user.uid}`,
                        });
                    await db.collection(res.user.uid).doc("Basket").set({ carrito: [] });
                    await db
                        .collection(res.user.uid)
                        .doc("history")
                        .collection("historial")
                        .doc(hoy.format("DD-MM-YYYY"))
                        .set({ historial: [{ mensaje: "Te registraste" }] });
                    autor(res.user.uid, datos.correo, datos.contraseña);
                } else {
                    alerta("Si eres menor de edad no puedes vender!");
                }
                //Si es usuario niormal
            } else {
                const res = await auth.createUserWithEmailAndPassword(
                    datos.correo,
                    datos.contraseña
                );
                await db.collection("users").doc(res.user.uid).set({
                    name: datos.nombre,
                    username: datos.usuario,
                    unicid: res.user.uid,
                    email: res.user.email,
                    country: datos.pais,
                    profile: null,
                    buyed: 0,
                    rol: datos.tipo,
                });
                await db.collection("usernames").doc(datos.usuario).set({
                    id: res.user.uid,
                });
                await db.collection(res.user.uid).doc("Basket").set({ carrito: [] });
                await db
                    .collection(res.user.uid)
                    .doc("history")
                    .collection("historial")
                    .doc(hoy.format("MM-DD-YYYY"))
                    .set({ historial: [{ mensaje: "Te registraste" }] });

                correctloaded("Ya puedes Iniciar sesion");
                window.location.replace("/");

                if (values.length > 0) {
                    const carrito = db.collection(res.user.uid).doc("Basket");
                    values.forEach(async(item, index) => {
                        let idItem = item.replaceAll(`"`, "")
                            //console.log(idItem);
                        await carrito.update({
                            carrito: firebase.firestore.FieldValue.arrayUnion(idItem)
                        })
                        localStorage.removeItem(`/basket${idItem}`)
                        if (index + 1 === values.length) {
                            correctloaded("Ya puedes Iniciar sesion");
                            window.location.replace("/");
                        }
                    });
                } else {
                    correctloaded("Ya puedes Iniciar sesion");
                    window.location.replace("/");
                }

            }
        }
    } catch (error) {
        if (error.code === "auth/invalid-email") {
            erroranimation("Correo invalido");
        } else if (error.code === "auth/email-already-in-use") {
            erroranimation("El correo ya esta en uso");
        } else if (error.code === "auth/network-request-failed") {
            erroranimation("Revisa la conexión a internet");
        } else {
            erroranimation(error.code);
            console.log(error);
        }
    }
};