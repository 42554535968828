import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
//import Correo from "./Correo";
import { obtenersolici, verificar } from "../Redux/allUsers";
import "../Styles/allusers.css";
//import Aprobarverificacion from "./Aprobarverificacion";
import Solicitud from "./Solicitud";

function Allusers({ rol }) {
  const [loadingRol, setLoadingRol] = useState(true);
  const [rolAdmin, setRolAdmin] = useState(false);
  const dispatch = useDispatch();
  const solicitudes = useSelector((store) => store.solicitudes.solicitudes);

  useEffect(() => {
    versolicitudes();
    document.title = "Admin - Solicitudes de verificacion";
    //eslint-disable-next-line
  }, []);

  const versolicitudes = () => {
    dispatch(obtenersolici());
  };

  useEffect(() => {
    if (rol.rol.admin) {
      setLoadingRol(false);
      setRolAdmin(true);
    } else {
      setLoadingRol(false);
      setRolAdmin(false);
    }
  }, [rol.rol]);

  if (loadingRol) return <h1>Loading...</h1>;

  return rolAdmin ? (
    <>
      <div className="contenedorsolicitudes">
        <h4>Solicitudes de verificacion</h4>
        {solicitudes !== undefined ? (
          solicitudes.length > 0 ? (
            solicitudes.map((sol) => <Solicitud info={sol} />)
          ) : (
            <h1>No hay solicitudes por el momento...</h1>
          )
        ) : (
          <h1>Loading...</h1>
        )}
      </div>
    </>
  ) : (
    <Redirect to="/" />
  );
}

export default Allusers;
