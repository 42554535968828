import React, { useEffect, useState } from "react";
import "../Styles/subir.css";
import { Fragment } from "react";
import { RiUploadCloud2Line } from "react-icons/ri";
import { alerta } from "../Redux/Cargas";
import { subirimagen } from "../Redux/subir";
import { useDispatch } from "react-redux";
import { Redirect, useParams } from "react-router-dom";
import Circle from "./Circle";
import fot from "../Imagenes/INFORMACION.jpg";
var zip = require("jszip")();

function Subir(props) {
  const [loadingRol, setLoadingRol] = useState(true);
  const [rolAdmin, setRolAdmin] = useState(false);

  const dispatch = useDispatch();
  const usuario = useParams();

  useEffect(() => {
    if (props.rol.rol.admin || props.rol.rol.autor) {
      setLoadingRol(false);
      setRolAdmin(true);
    } else {
      setLoadingRol(false);
      setRolAdmin(false);
    }
  }, [props.rol.rol]);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    document.title = "Subir arte";
    if (props.user) {
      const usuariodata = JSON.parse(sessionStorage.getItem("currentuser"));
      if (usuariodata !== undefined && usuariodata !== null) {
        if (usuariodata.data.username !== usuario.usueractual) {
          window.location.replace("/");
        } else {
          setLoading(false);
        }
      }
    }
  }, [props.user]);

  //Informacion del archivo
  const [datosimagen, setDatosimagen] = useState({
    title: "",
    description: "",
    category: "",
    price: "",
    likes: 0,
  });

  const agregardato = (e) => {
    setDatosimagen({
      ...datosimagen,
      [e.target.name]: e.target.value,
    });
  };

  const [files, setFiles] = useState([]);
  const [archive, setArchive] = useState({});
  const [imagenprevia, setImagenprevia] = useState();

  //convertir a base64
  const convertirBase64 = (archivo) => {
    if (archivo.length > 0) {
      try {
        setImagenprevia(archivo[0]);
        const arrayArchivos = [];
        Array.from(archivo).forEach((archivo) => {
          var reader = new FileReader();
          reader.readAsDataURL(archivo);
          reader.onload = function () {
            var arrayAuxiliar = [];
            var base64 = reader.result;
            arrayAuxiliar = base64.split(",");
            arrayArchivos.push({
              base64: arrayAuxiliar[1],
              nombre: archivo.name,
            });
          };
        });
        setFiles({
          ...files,
          arrayArchivos,
        });
        setArchive(archivo);
      } catch (err) {
        console.log(err);
      }
    } else {
      alerta("No haz seleccionado una imagen");
    }
  };

  //Comprimir y descargar
  const exportarAZip = () => {
    if (files.length === 0) {
      alerta("No haz seleccionado una imagen");
    } else if (
      datosimagen.title === "" ||
      datosimagen.description === "" ||
      datosimagen.price === "" ||
      datosimagen.category === ""
    ) {
      alerta("Faltan datos");
    } else {
      try {
        const { arrayArchivos } = files;
        var folder = zip.folder("Contenido de todo");
        arrayArchivos.map((archivo) => {
          folder.file(archivo.nombre, archivo.base64, { base64: true });
          return true;
        });
        zip.generateAsync({ type: "blob" }).then(function (content) {
          //Aqui se descarga
          //saveAs(content, "Gracias por escojernos ;).zip");
          dispatch(
            subirimagen(datosimagen, content, imagenprevia, usuario.usueractual)
          );
        });
        zip = require("jszip")();
        setArchive([]);
        setDatosimagen({
          title: "",
          description: "",
          category: "",
          price: "",
        });
        setFiles([]);
      } catch (error) {
        console.log(error);
      }
    }
  };

  const pre = [];
  for (var i = 0; i <= 30; i++) {
    pre.push(i);
  }

  if (loadingRol) return <h1>Loading...</h1>;

  return rolAdmin ? (
    <Fragment>
      {loading ? (
        <Circle />
      ) : (
        <div className="subirContainer">
          <div className="formulario">
            <h3>Subir contenido</h3>
            <form className="formu">
              <input
                type="text"
                placeholder="Titulo"
                value={datosimagen.title}
                name="title"
                className="titulo"
                onChange={agregardato}
              />
              <textarea
                placeholder="Descripcion"
                value={datosimagen.description}
                name="description"
                className="descripcion"
                rows="10"
                onChange={agregardato}
              />
              <br />

              <select
                name="category"
                value={datosimagen.category}
                id="categoria"
                className="categoria"
                onChange={agregardato}>
                <option hidden> ---CATEGORIA--- </option>
                <option value="Animales">Animales</option>
                <option value="Anime">Anime</option>
                <option value="Arte nacional">Arte nacional</option>
                <option value="Comida">Comida</option>
                <option value="Dibujos animados">Dibujos animados</option>
                <option value="Fantasia">Fantasia</option>
              </select>

              <select
                name="price"
                id="precio"
                className="precio"
                value={datosimagen.price}
                onChange={agregardato}>
                <option hidden> ---PRECIO--- </option>
                {pre.map((i) =>
                  i === 0 ? (
                    <option key={i} value={i}>
                      Gratis
                    </option>
                  ) : (
                    <option key={i} value={i}>
                      ${i}.00
                    </option>
                  )
                )}
              </select>
            </form>
            <img
              src={fot}
              alt=""
              style={{ width: "90%", margin: "30px", borderRadius: "50px" }}
            />
          </div>
          <div className="subirArchivo">
            <input
              type="file"
              id="imagenfile"
              multiple
              onChange={(e) => convertirBase64(e.target.files)}
              accept="image/jpg, image/jpeg, image/png, .ai"
            />
            <label htmlFor="imagenfile" className="subirimagen">
              {archive !== undefined ? (
                archive.length > 0 ? (
                  Object.entries(archive).map((item) => (
                    <div className="nombredearchivos" key={item[0]}>
                      {item[1].name}
                    </div>
                  ))
                ) : (
                  <Fragment>
                    <RiUploadCloud2Line className="nube" />
                    <h3>Subir imagenes</h3>
                  </Fragment>
                )
              ) : (
                <Fragment>
                  <RiUploadCloud2Line className="nube" />
                  <h3>Subir imagenes</h3>
                </Fragment>
              )}
            </label>
            <button className="subir" onClick={exportarAZip}>
              Publicar
            </button>
          </div>
        </div>
      )}
    </Fragment>
  ) : (
    <Redirect to="/" />
  );
}

export default Subir;
