import React, { useState } from 'react'
import { Fragment } from 'react'
import { useDispatch } from 'react-redux'
import { alerta, erroranimation } from '../Redux/Cargas'
import { recuperarcontraseña } from '../Redux/Login'
import '../Styles/Restaurarpass.css'

function Restaurarpass() {
    const [enviado, setEnviado] = useState(false)
    const [email, setEmail] = useState('')
    const dispatch = useDispatch()


    const addEmail = (e)=>{
        setEmail(e.target.value)
    }

    const restaurar = {
        enviarreset: (e)=>{
            try{
                e.preventDefault()
                if(email===''){
                    alerta('Te faltó colocar el correo!!')
                }else{
                    setEnviado(true)
                    document.getElementById("recuperarboton").disabled=true
                    document.getElementById("recuperaremail").disabled=true
                    dispatch(recuperarcontraseña(email))
                }
            }catch(error){
                erroranimation()
                setEnviado(false)
                document.getElementById("recuperarboton").disabled=false
                document.getElementById("recuperaremail").disabled=false
            }
        },
        corregir: ()=>{
            setEnviado(false)
            document.getElementById("recuperarboton").disabled=false
            document.getElementById("recuperaremail").disabled=false
            document.getElementById("recuperaremail").focus()
        }
    }

    return (
        <Fragment>
            <form onSubmit={restaurar.enviarreset} className='restaurarpass'>
                <h1>Recuperar contraseña</h1>
                <h4>Email de cuenta: </h4>
                <input type="email" placeholder='Email' onChange={addEmail} id='recuperaremail' className='correo'/>
                <button type='submit' id='recuperarboton' className='boton'>Restablecer contraseña</button>
            {
                (enviado)?(
                    <Fragment>
                        <p>
                            En unos minutos te llegará un correo con un link de reset para tu contraseña
                        </p>
                        <p>
                            Si NO te llega, intenta <button onClick={restaurar.corregir}>corregir el correo</button> o <button onClick={restaurar.enviarreset}>enviar el enlace nuevamente</button>
                        </p>
                    </Fragment>
                ):(
                    <Fragment />
                    )
                }
            </form>
        </Fragment>
    )
}

export default Restaurarpass
