import { auth, db, storage } from "./firebase";
import { loadanimation, erroranimation, correctloaded } from "./Cargas";
import { v4 as uuidv4 } from "uuid";
import { obtenerProducts } from "./productos";
import firebase from "firebase/app";
import moment from "moment";

export const subirimagen =
  (datos, file, foto, username) => async (dispatch, getState) => {
    const user = auth.currentUser;
    const id = uuidv4();
    const hoy = moment();
    try {
      loadanimation();
      const postRef = storage
        .ref(user.uid)
        .child("posts")
        .child(id)
        .child("Gracias por preferirnos ;).zip");
      const imagenRef = storage
        .ref(user.uid)
        .child("posts")
        .child(id)
        .child("previa");
      await postRef.put(file).then(async () => {
        await imagenRef.put(foto).then(async () => {
          const imagenURL = await imagenRef.getDownloadURL();
          await db.collection(user.uid).doc(id).set({
            title: datos.title,
            description: datos.description,
            category: datos.category,
            price: datos.price,
            likes: datos.likes,
            downloads: 0,
            previo: imagenURL,
            autor: user.uid,
            imageid: id,
          });
          await db.collection("AllProducts").doc(id).set({
            title: datos.title,
            description: datos.description,
            category: datos.category,
            price: datos.price,
            likes: datos.likes,
            downloads: 0,
            previo: imagenURL,
            autor: user.uid,
            imageid: id,
          });
        });
      });
      const historiahoy = db
        .collection(user.uid)
        .doc("history")
        .collection("historial")
        .doc(hoy.format("MM-DD-YYYY"));
      const historiahoyver = await historiahoy.get();
      if (historiahoyver.exists) {
        await historiahoy.update({
          historial: firebase.firestore.FieldValue.arrayUnion({
            mensaje: `Publicaste ${datos.title}`,
          }),
        });
      } else {
        await db
          .collection(user.uid)
          .doc("history")
          .collection("historial")
          .doc(hoy.format("MM-DD-YYYY"))
          .set({ historial: [{ mensaje: `Publicaste ${datos.title}` }] });
      }
      correctloaded("Enhorabuena, tu imagen se subio correctamente!!!");
    } catch (error) {
      console.log(error);
      erroranimation();
    }
  };

export const eliminarPost = (postid, allpostid, user) => async (dispatch) => {
  const fireId = postid;
  const hoy = moment();
  const storageId = allpostid;

  try {
    loadanimation();
    await db
      .collection(user)
      .doc(fireId)
      .delete()
      .then(async () => {
        await db
          .collection("AllProducts")
          .doc(storageId)
          .delete()
          .then(async () => {
            const postRef = storage
              .ref(user)
              .child("posts")
              .child(storageId)
              .child("Gracias por preferirnos ;).zip");
            const imagenRef = storage
              .ref(user)
              .child("posts")
              .child(storageId)
              .child("previa");
            await postRef.delete();
            await imagenRef.delete();
          });
      });
    const historiahoy = db
      .collection(user)
      .doc("history")
      .collection("historial")
      .doc(hoy.format("MM-DD-YYYY"));
    const historiahoyver = await historiahoy.get();
    if (historiahoyver.exists) {
      await historiahoy.update({
        historial: firebase.firestore.FieldValue.arrayUnion({
          mensaje: `Eliminaste un post`,
        }),
      });
    } else {
      await db
        .collection(user)
        .doc("history")
        .collection("historial")
        .doc(hoy.format("MM-DD-YYYY"))
        .set({ historial: [{ mensaje: `Eliminaste un post` }] });
    }
    correctloaded("Se elimino la imagen");
    dispatch(obtenerProducts(user));
  } catch (error) {
    console.log(error);
  }
};
