import { auth, db } from "./firebase.js";
import firebase from "firebase/app";
import { correctloaded } from "./Cargas.js";

const initialCarrito = {
    basket: [],
    loading: false,
};

const AGREGAR_A_CARRITO = "AGREGAR_A_CARRITO";
const LOADING = "LOADING";

export default function reducerAgregarcarrito(state = initialCarrito, action) {
    switch (action.type) {
        case LOADING:
            return {...state, loading: true };
        case AGREGAR_A_CARRITO:
            return {...state, basket: action.payload, loading: false };
        default:
            return state;
    }
}

//Agregar a carrito
export const agregarcarrito = (item, fromDownload = false) => async(dispatch, getState) => {
    const usuario = auth.currentUser;
    try {
        const ref = await db.collection("AllProducts").doc(item.id).get();
        if (usuario) {
            const carrito = db.collection(usuario.uid).doc("Basket");
            await carrito.update({
                carrito: firebase.firestore.FieldValue.arrayUnion(ref.data().imageid),
            });
            await dispatch(obtenercarrito());
            if (fromDownload) {
                window.location.replace('/carrito')
            } else {
                correctloaded("Agregado");
            }
        } else {
            localStorage.setItem(
                `/basket${ref.data().imageid}`,
                JSON.stringify(ref.data().imageid)
            );
            await dispatch(obtenercarrito());
            if (fromDownload) {
                window.location.replace('/carrito')
            } else {
                correctloaded("Agregado");
            }
        }
    } catch (err) {
        console.log(err);
    }
};

//Eliminar de carrito
export const eliminarcarrito = (item) => async(dispatch, getState) => {
    dispatch({
        type: LOADING,
    });
    const usuario = auth.currentUser;
    try {
        if (usuario) {
            const carrito = db.collection(usuario.uid).doc("Basket");
            await carrito.update({
                carrito: firebase.firestore.FieldValue.arrayRemove(item),
            });
            await dispatch(obtenercarrito());
        } else {
            localStorage.removeItem(`/basket${item}`);
            await dispatch(obtenercarrito());
        }
    } catch (err) {
        console.log(err);
    }
};

//llamar a carrito
export const obtenercarrito = () => async(dispatch, getState) => {
    const usuario = auth.currentUser;
    try {
        if (usuario) {
            const res = await db.collection(usuario.uid).doc("Basket").get();
            dispatch({
                type: AGREGAR_A_CARRITO,
                payload: res.data(),
            });
        } else {
            var values = [],
                keys = Object.keys(localStorage),
                i = keys.length;

            while (i--) {
                if (keys[i].substring(0, 7) === "/basket") {
                    values.push(localStorage.getItem(keys[i]));
                }
            }

            dispatch({
                type: AGREGAR_A_CARRITO,
                payload: values,
            });
        }
    } catch (err) {
        console.log(err);
    }
};