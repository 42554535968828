import React from "react";
import { useDispatch } from "react-redux";
import { editarImagen, eliminarImagen } from "../Redux/edicion";
import "../Styles/editarPerfil.css";

function Editfoto(props) {
  const dispatch = useDispatch();

  const seleccionarArchivo = (imagen) => {
    if (
      imagen.target.files[0] !== null &&
      imagen.target.files[0] !== undefined
    ) {
      dispatch(editarImagen(imagen.target.files[0]));
    }
  };

  const eliminar = () => {
    dispatch(eliminarImagen(props.info.username));
  };

  return (
    <div className="contenedor">
      <div className="opciones">
        <h5>Cambiar foto de perfil</h5>
        <input
          type="file"
          id="perfilfoto"
          style={{ display: "none" }}
          accept="image/jpg, image/jpeg"
          onChange={(e) => seleccionarArchivo(e)}
        />
        <label htmlFor="perfilfoto" className="subir">
          Subir una foto
        </label>
        {props.foto !== null && (
          <button className="eliminar" onClick={eliminar}>
            Eliminar foto actual
          </button>
        )}
        <button
          className="cancelar"
          onClick={() => {
            props.cerrar.desactivar();
          }}>
          Cancelar
        </button>
      </div>
    </div>
  );
}

export default Editfoto;
