import React, { Fragment } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { auth, db } from "../Redux/firebase";
import "../Styles/Total.css";
import { PayPalButton } from "react-paypal-button-v2";
import { useDispatch } from "react-redux";
import { confirmar } from "../Redux/descargar";
import { correctloaded } from "../Redux/Cargas";
import Login from "./Login";

function Total(props) {
  const dispatch = useDispatch();
  const user = auth.currentUser;
  var suma = 0;
  const [totalFinal, setTotalFinal] = useState(0);
  const [paymentError, setPaymentError] = useState(false);
  const [cancelPayment, setCancelPayment] = useState(false);
  const [checkedAll, setCheckedAll] = useState(false);
  const total = [];
  const { allLicencesChecked, finalTotal } = props;

  useEffect(() => {
    setCancelPayment(false);
    setPaymentError(false);
  }, []);

  useEffect(() => {
    fetchData();
    //eslint-disable-next-line
  }, [props.precios, total.length]);

  useEffect(() => {
    if (finalTotal) {
      if (allLicencesChecked) {
        setCheckedAll(true);
        setPaymentError(false);
      } else {
        setCheckedAll(false);
      }
    } else {
      setCheckedAll(true);
      setPaymentError(false);
    }
  }, [finalTotal, allLicencesChecked]);

  useEffect(() => {
    if (paymentError && cancelPayment) {
      setPaymentError(false);
      setCancelPayment(false);
    }
    //eslint-disable-next-line
  }, [cancelPayment, paymentError]);

  const fetchData = async () => {
    try {
      if (props.precios !== undefined) {
        const newPrice = props.precios.map(async (i) => {
          const res = await db.collection("AllProducts").doc(i).get();
          total.push(parseInt(res.data().price));
          return total;
        });
        Promise.all(newPrice).then((data) => {
          if (data[0] !== undefined) {
            const totalFinal = data[0].map((j) => {
              suma += j;
              return suma;
            });
            Promise.all(totalFinal).then((data) => {
              setTotalFinal(data[data.length - 1]);
            });
          } else {
            setTotalFinal(0);
          }
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Fragment>
      <div className={`total ${user ? "isuser" : " "}`}>
        {user ? (
          <>
            <div className="preciototal">
              <h3>Total</h3>
              <h3>{`$${totalFinal}.00`}</h3>
            </div>
            <PayPalButton
              amount={checkedAll ? totalFinal : null}
              onError={() => {
                setPaymentError(true);
              }}
              onCancel={() => {
                setCancelPayment(true);
              }}
              onSuccess={(details, data) => {
                correctloaded("Pago efectuado correctamente..!!");
                dispatch(confirmar(props.precios));
              }}
            />
            {paymentError && (
              <p className="errorPay">
                Todas las imagenes tienen que tener un tipo de licencia
                escojidas
              </p>
            )}
          </>
        ) : (
          <Login fromTotal={true} />
        )}
      </div>
    </Fragment>
  );
}

export default Total;
