import React from "react";
import emailjs from "emailjs-com";
import { useDispatch } from "react-redux";
import { verificar } from "../Redux/allUsers";
import "../Styles/Aprobarverificacion.css";

function Aprobarverificacion(props) {
  const dispatch = useDispatch();
  function emailaprovado(e) {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_t1omwg9",
        "template_veshpqt",
        e.target,
        "user_4zKplIoNAg4oNAOAP3ECd"
      )
      .then(
        (result) => {
          dispatch(verificar(props.id));
        },
        (error) => {
          console.log(error.text);
        }
      );
    e.target.reset();
  }

  return (
    <form className="contact-form-accept" onSubmit={emailaprovado}>
      <input type="hidden" name="contact_number" />
      <div className="datosDeUsuario" style={{ display: "none" }}>
        <div>
          <input type="text" name="to_name" defaultValue={props.username} />
        </div>
        <div>
          <input type="email" name="user_email" defaultValue={props.correo} />
        </div>
      </div>
      <input type="submit" value="Verificar" className="verificar" />
    </form>
  );
}

export default Aprobarverificacion;
