import React from 'react'
import { Link } from 'react-router-dom'
import { Fragment } from 'react'
import '../Styles/InformacionDeUsuario.css'

function InformacionDeUsuario(props) {
    return (
        <Fragment>
            <div className='informacionDeusuario'>
                <h5 className='idDeUsuario'>ID de usuario: <p>{props.info.unicid}</p></h5>
                <h5 className='correo'>Email: <p>{props.info.email}</p></h5>
                <h5 className='rol'>Tipo de usuario: <p>{props.info.rol}</p></h5>
                <h5 className='direccion'>Direccion: <p>{props.info.direction}</p></h5>
                {
                    (props.own) ? (
                        <div className='botonDeEditar'>
                            <Link className='edit' to='/cuenta/ajustes'>editar</Link>
                        </div>
                    ):(
                        null
                    )
                }
            </div>
        </Fragment>
    )
}

export default InformacionDeUsuario
