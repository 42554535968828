import { db } from "./firebase";

const initialstate = {
    products: [],
};

const OBTENER_ALL_PRODUCTS = "OBTENER_ALL_PRODUCTS";

export default function reducerAllproducts(state = initialstate, action) {
    switch (action.type) {
        case OBTENER_ALL_PRODUCTS:
            return {...state, products: action.payload };
        default:
            return state;
    }
}

export const obtenerallproducts = (categoria) => async(dispatch, getState) => {
    try {
        if (categoria === undefined || categoria === null) {
            const datos = await db.collection("AllProducts").get();
            const array = datos.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
            dispatch({
                type: OBTENER_ALL_PRODUCTS,
                payload: array,
            });
        } else {
            const datos = await db.collection("AllProducts").get();
            const array = datos.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
            const arrayfiltrado = array.filter((cate) => cate.category === categoria);
            dispatch({
                type: OBTENER_ALL_PRODUCTS,
                payload: arrayfiltrado,
            });
        }
    } catch (error) {
        console.log(error);
    }
};