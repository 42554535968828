import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { auth, db, functions } from "../Redux/firebase";
import { BiLike } from "react-icons/bi";
import { BsDownload } from "react-icons/bs";
import { FiShoppingCart } from "react-icons/fi";
import { CgCloseO } from "react-icons/cg";
import "../Styles/productpage.css";
import { useDispatch, useSelector } from "react-redux";
import { addlike, buscarlikes, deletelike } from "../Redux/Like";
import { agregarcarrito } from "../Redux/basket";
import { GrClose } from "react-icons/gr";
import { descargar } from "../Redux/descargar";
import Login from "./Login";
import Total from "./Total";

function Productpage(props) {
  const user = auth.currentUser;
  const dispatch = useDispatch();
  const [autor, setAutor] = useState(null);
  const [confirmardescarga, setConfirmardescarga] = useState(false);
  const likes = useSelector((store) => store.likes.likes);

  useEffect(() => {
    fetchData();
    likebuscar();
  }, []);

  const likebuscar = () => {
    dispatch(buscarlikes());
  };

  const fetchData = async () => {
    try {
      if (props !== undefined) {
        const autor = await db.collection("users").doc(props.datos.autor).get();
        setAutor(autor.data().username);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const agregarlike = () => {
    if (user) {
      dispatch(addlike(props.datos.imageid));
    } else {
      open();
      props.close.cerrar();
    }
  };

  function open() {
    var mostrarLogin = document.getElementById("ventanaLogin");
    mostrarLogin.style.display = "block";
  }
  function cerrar() {
    var mostrarLogin = document.getElementById("ventanaLogin");
    mostrarLogin.style.display = "none";
  }

  return (
    <div className="paginadeproducto">
      <div className="popUpDeLogin" id="ventanaLogin">
        <button className="close" onClick={cerrar}>
          <CgCloseO />
        </button>
        <Login />
      </div>
      {props !== undefined ? (
        <>
          <div className="contedelconte">
            <button
              className="cerrarboton"
              onClick={() => props.close.cerrar()}>
              <CgCloseO />
            </button>
            <div className="contenidoinfo">
              <div
                className="imagen"
                style={{
                  backgroundImage: `url(${props.datos.previo})`,
                  backgroundSize: "contain",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                }}>
                <div className="marcaagua"></div>
              </div>
              <div className="informacion">
                <h4>{props.datos.title}</h4>
                <div className="datitos">
                  <div className="datitosinfo">
                    <Link to={`/${autor}`} className="autor">
                      {autor}
                    </Link>
                    <p className="categoria">{props.datos.category}</p>
                    <p className="megusta">{props.datos.likes} Me gusta</p>
                  </div>
                  <div className="datitosprecion">
                    <h4>${props.datos.price}.00</h4>
                  </div>
                </div>
                <div className="botoneraopcione">
                  {likes !== undefined &&
                    (likes.includes(props.datos.imageid) ? (
                      <button
                        onClick={() =>
                          dispatch(deletelike(props.datos.imageid))
                        }
                        className="liked">
                        <BiLike />
                      </button>
                    ) : (
                      <button onClick={() => agregarlike()}>
                        <BiLike />
                      </button>
                    ))}
                  {user ? (
                    <button
                      onClick={() => setConfirmardescarga(!confirmardescarga)}>
                      {confirmardescarga ? <GrClose /> : <BsDownload />}
                    </button>
                  ) : (
                    <button
                      onClick={() => {
                        props.close.cerrar();
                        open();
                      }}>
                      <BsDownload />
                    </button>
                  )}
                  <button onClick={() => dispatch(agregarcarrito(props.datos))}>
                    <FiShoppingCart />
                  </button>
                </div>
                {confirmardescarga ? <Total precios={props.precios} /> : null}
              </div>
            </div>
          </div>
          <p className="descripcion">{props.datos.description}</p>
        </>
      ) : (
        <div className="contedelconte">
          <h1>Hubo un error</h1>
        </div>
      )}
    </div>
  );
}

export default Productpage;
