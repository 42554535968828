import React from "react";
import "../Styles/Categorias.css";
import { IoIosArrowDown } from "react-icons/io";
import { obtenerallproducts } from "../Redux/Allimages";
import { useDispatch } from "react-redux";

function Categorias() {
  const dispatch = useDispatch();
  return (
    <>
      <nav className="categorias">
        <input type="checkbox" id="categoriasopciones" />
        <label htmlFor="categoriasopciones" className="cattitle">
          Categorías
          <IoIosArrowDown className="flechacat" />
        </label>
        <ul className="menucategorias">
          <li>
            <button onClick={() => dispatch(obtenerallproducts("Animales"))}>
              Animales
            </button>
          </li>
          <li>
            <button onClick={() => dispatch(obtenerallproducts("Anime"))}>
              Anime
            </button>
          </li>
          <li>
            <button
              onClick={() => dispatch(obtenerallproducts("Arte nacional"))}>
              Arte nacional
            </button>
          </li>
          <li>
            <button onClick={() => dispatch(obtenerallproducts("Comida"))}>
              Comida
            </button>
          </li>
          <li>
            <button
              onClick={() => dispatch(obtenerallproducts("Dibujos animados"))}>
              Dibujos animados
            </button>
          </li>
          <li>
            <button onClick={() => dispatch(obtenerallproducts("Fantasia"))}>
              Fantasía
            </button>
          </li>
          <li>
            <button onClick={() => dispatch(obtenerallproducts())}>
              --Eliminar filtro--
            </button>
          </li>
        </ul>
      </nav>
    </>
  );
}

export default Categorias;
