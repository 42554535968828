import React from "react";
import { useEffect, useState } from "react";
import { BsCloudDownload, BsTrash } from "react-icons/bs";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { eliminarcarrito } from "../Redux/basket";
//import { descargar } from "../Redux/descargar";
import { auth, db } from "../Redux/firebase";
import { GrClose } from "react-icons/gr";
import { BsDownload } from "react-icons/bs";
import Total from "./Total";
import { AiOutlineQuestionCircle } from "react-icons/ai";

function BasketItem(props) {
  const user = auth.currentUser;
  const dispatch = useDispatch();
  const [data, setData] = useState("");
  const [autor, setAutor] = useState("");
  const [confirmardescarga, setConfirmardescarga] = useState(false);
  const [licenceType, setLicenceType] = useState(null);
  const [licenceNotSelected, setLicenceNotSelected] = useState(false);
  const precio = [];
  const helpMessages = {
    personalMessage:
      "Podrás utilizar el arte de forma personal sin derecho a comercializarlo, esta licencia tiene una vigencia indefinida, por favor; revisar los términos y condiciones.",
    comercialMessage:
      "Podrás comercializar el arte de manera limitada, por un período de 1 año, después la licencia se convierte en personal, por favor; revisar los términos y condiciones.",
  };

  const { personalMessage, comercialMessage } = helpMessages;

  if (props) {
    precio.push(props.id);
  }
  useEffect(() => {
    fetchData();
    //eslint-disable-next-line
  }, [props.id]);

  useEffect(() => {
    setLicenceNotSelected(false);
  }, []);

  const fetchData = async () => {
    try {
      const res = await db.collection("AllProducts").doc(props.id).get();
      if (res.data() !== undefined) {
        const autor = await db.collection("users").doc(res.data().autor).get();
        setAutor(autor.data().username);
        setData(res.data());
      } else {
        setData("Puede que este producto ya no este disponible");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const checkLicenceType = () => {
    if (licenceType) {
      setConfirmardescarga(!confirmardescarga);
    } else {
      setLicenceNotSelected(true);
    }
  };

  const selectLicenceType = (e) => {
    setLicenceType(e.target.value);
    setLicenceNotSelected(false);
    props.addLicenceChecked();
  };

  return (
    <>
      <div className="basketitem">
        {data !== undefined ? (
          <>
            <div className="basketItemInfo">
              <div
                className="foto"
                style={{
                  backgroundImage: `url(${data.previo})`,
                  backgroundSize: "contain",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                }}>
                <div className="marcaagua"></div>
              </div>
              <div className="info">
                <h1 className="titulo">{data.title}</h1>
                <p className="categoria">{data.category}</p>
                <Link to={`/${autor}`} className="user">
                  {autor}
                </Link>
                <div className="description">
                  <p>{data.description}</p>
                </div>
              </div>
              <div className="botones">
                <h3 className="precio">${data.price}.00</h3>
                {user ? (
                  <>
                    <button
                      onClick={checkLicenceType}
                      className="descargar"
                      title="Descargar esta imagen">
                      {confirmardescarga ? <GrClose /> : <BsDownload />}
                    </button>
                    {licenceNotSelected && (
                      <p className="licenciaError">
                        Escoja un tipo de licencia
                      </p>
                    )}
                  </>
                ) : (
                  <h4>Inicia sesion para comprar</h4>
                )}
                {/* <button
              className="descargar"
              title="Descargar esta imagen"
              onClick={() => dispatch(descargar(data.imageid, data.autor))}>
              <BsCloudDownload />
            </button> */}
                <button
                  className="eliminar"
                  title="Eliminar esta imagen del carrito"
                  onClick={() => dispatch(eliminarcarrito(data.imageid))}>
                  <BsTrash />
                </button>
              </div>
            </div>
            {confirmardescarga ? <Total precios={precio} /> : null}
            <div className="licences">
              <h6>Elija un tipo de licencia de uso</h6>
              <ul className="licencesCheck">
                <li>
                  <input
                    type="radio"
                    name={data.title}
                    id={`${data.previo}per`}
                    value="Personal"
                    onChange={selectLicenceType}
                  />
                  <label htmlFor={`${data.previo}per`}>Licencia Personal</label>
                  <AiOutlineQuestionCircle className="askIcon" />
                  <p className="help">{personalMessage}</p>
                </li>
                <li>
                  <input
                    type="radio"
                    name={data.title}
                    id={`${data.previo}com`}
                    value="Comercial-Personal"
                    onChange={selectLicenceType}
                  />
                  <label htmlFor={`${data.previo}com`}>
                    Licencia Comercial-Personal
                  </label>
                  <AiOutlineQuestionCircle className="askIcon" />
                  <p className="help">{comercialMessage}</p>
                </li>
              </ul>
              <p>
                **Véase la sección licencias de compra en{" "}
                <a href="/terminos" target="_blank" rel="noopener noreferrer">
                  Términos y condiciones
                </a>{" "}
              </p>
            </div>
          </>
        ) : (
          <>
            <h4>Puede que el producto ya no este disponible</h4>
            <button
              className="eliminar"
              title="Eliminar esta imagen del carrito"
              onClick={() => dispatch(eliminarcarrito(props.id))}>
              <BsTrash />
            </button>
          </>
        )}
      </div>
    </>
  );
}

export default BasketItem;
