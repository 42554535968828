import { db } from "./firebase"

const initialState = {
    compras: [],
    loading: true,
    error: false
}


const OBTENER_COMPRAS = 'OBTENER_COMPRAS'
const SET_ERROR = 'SET_ERROR'

export default function comprasReducer(state = initialState, action) {
    switch (action.type) {
        case OBTENER_COMPRAS:
            return {
                ...state,
                compras: action.payload,
                loading: false,
                error: false
            }
        case SET_ERROR:
            return {
                ...state,
                error: true,
                loading: false,
            }
        default:
            return state
    }
}


export const obtenerCompras = () => async(dispatch, getState) => {
    try {
        const results = await db.collection('users').get()
        const data = results.docs.map(item => ({...item.data() }))
        dispatch({
            type: OBTENER_COMPRAS,
            payload: data
        })
    } catch (error) {
        dispatch({
            type: SET_ERROR
        })
    }
}