import React from 'react'
import { FiAlertTriangle } from "react-icons/fi";
import { useDispatch } from 'react-redux';
import { eliminarPost } from '../Redux/subir';
import '../Styles/Confirmacion.css'

function Confirmacioneliminar(props) {
    const dispatch = useDispatch()

    const eliminarProceso = ()=>{
        props.funct.cerrar()
        dispatch(eliminarPost(props.fotoId, props.allId, props.usuario))
    }


    return (
        <div className='confirmacion'>
            <div className='confirmacionConte'>
                <h3>Eliminar imagen</h3>
                <p><FiAlertTriangle /> No podras recuperar los datos de este post</p>
                <button className='eli' onClick={()=>eliminarProceso()}>Eliminar</button>
                <button onClick={props.funct.cerrar} className='cancelar'>Cancelar</button>
            </div>
        </div>
    )
}

export default Confirmacioneliminar
