import React, { Fragment, useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { FiArrowRightCircle, FiArrowLeftCircle } from "react-icons/fi";
import {
  registrarUsuario,
  startFacebookLogin,
  startGoogleLogin,
} from "../Redux/registro";
import { login } from "../Redux/Login";
import { alerta } from "../Redux/Cargas";
import Flag from "react-world-flags";
import "../Styles/Login.css";
import "../Styles/register.css";
import "../Styles/Registroavanzado.css";
import { GrGoogle } from "react-icons/gr";
import { FaFacebookSquare } from "react-icons/fa";
import { GoAlert } from "react-icons/go";
import moment from "moment";
import useTerminosCheck from "../hooks/useTerminosCheck";

moment.locale("es");

function Login({ fromTotal }) {
  const dispatch = useDispatch();
  const [pagina, setPagina] = useState(true);
  const [edad, setEdad] = useState(18);
  const [termsMessage, setTermsMessage] = useState(false);

  const [CheckBoxTerms, checkInput] = useTerminosCheck();

  //Datos para registrarse
  const [datosusuario, setDatosusuario] = useState({
    nombre: "",
    usuario: "",
    correo: "",
    contraseña: "",
    pais: "",
    tipo: "",
    direction: "",
    edad: "",
    payemail: "",
  });

  const formulario = {
    registro: () => {
      setPagina(!pagina);
    },
    logeo: () => {
      setPagina(!pagina);
    },
  };

  useEffect(() => {
    setTermsMessage(false);
  }, []);

  useEffect(() => {
    if (checkInput) {
      setTermsMessage(false);
      if (datosusuario.tipo === "vendedor" && datosusuario.edad >= 18) {
        var botonRegistro1 = document.getElementById("botoregistro");
        if (botonRegistro1) {
          botonRegistro1.disabled = false;
        }
      } else if (datosusuario.tipo === "regular") {
        var botonRegistro2 = document.getElementById("botoregistro");
        if (botonRegistro2) {
          botonRegistro2.disabled = false;
        }
      } else {
        return false;
      }
    } else {
      var botonRegister = document.getElementById("botoregistro");
      if (botonRegister) {
        botonRegister.disabled = true;
      }
    }
    //eslint-disable-next-line
  }, [checkInput]);

  const agregarDatos = (e) => {
    if (e.target.name === "edad") {
      const hoy = moment();
      const nacimiento = moment(e.target.value);
      const age = hoy.diff(nacimiento, "years");
      setEdad(age);
      setDatosusuario({
        ...datosusuario,
        [e.target.name]: age,
      });
    } else {
      setDatosusuario({
        ...datosusuario,
        [e.target.name]: e.target.value,
      });
    }
  };

  //funcion para registrarse
  const enviarDatos = (e) => {
    e.preventDefault();
    if (
      datosusuario.nombre === "" ||
      datosusuario.usuario === "" ||
      datosusuario.correo === "" ||
      datosusuario.contraseña === "" ||
      datosusuario.pais === "" ||
      datosusuario.tipo === ""
    ) {
      alerta("porfavor, rellene todos los campos");
    } else {
      if (datosusuario.contraseña.length <= 6) {
        alerta("La contraseña tiene que tener mas de 6 caracteres");
      } else {
        if (datosusuario.tipo === "vendedor") {
          if (
            datosusuario.direction === "" ||
            datosusuario.edad === "" ||
            datosusuario.payemail === ""
          ) {
            alerta("porfavor, rellene todos los campos");
          } else {
            e.preventDefault();
            dispatch(registrarUsuario(datosusuario));
          }
        } else {
          e.preventDefault();
          dispatch(registrarUsuario(datosusuario));
        }
      }
    }
  };

  //Datos para logearse
  const [loginDatos, setLoginDatos] = useState({
    correoLog: "",
    contraseñaLog: "",
  });

  const logeoDatos = (e) => {
    setLoginDatos({
      ...loginDatos,
      [e.target.name]: e.target.value,
    });
  };

  //funcion para logearse
  const logear = (e) => {
    e.preventDefault();
    dispatch(login(loginDatos));
  };

  //funcion de google login

  const handleGoogleLogin = () => {
    dispatch(startGoogleLogin(loginDatos));
  };

  //funcion de facebook login
  const handleFacebookLogin = () => {
    dispatch(startFacebookLogin());
  };

  const networkSignIn = (network) => {
    if (checkInput) {
      if (network === "google") {
        handleGoogleLogin();
      } else if (network === "facebook") {
        handleFacebookLogin();
      } else {
      }
    } else {
      setTermsMessage(true);
    }
  };

  return (
    <Fragment>
      <div className="cuerpoDeLogin">
        <div className="container">
          {!fromTotal && (
            <div className="imagenDeLogin">
              <div className="decoracion"></div>
              <div className="decoracion1">
                <div className="decoracion1Inter"></div>
              </div>
            </div>
          )}
          {pagina ? (
            /* FORMULARIO PARA LOGEARSE */
            <form
              action=""
              className={`loginFormContainer ${
                fromTotal ? "noUserContainer" : "isUserContainer"
              }`}
              onSubmit={logear}>
              <h3>Iniciar sesion</h3>
              <div
                className={`camposDeLogin ${
                  fromTotal ? "nouserLogin" : "isuserLogin"
                }`}>
                <input
                  type="email"
                  placeholder="Correo"
                  autoComplete="off"
                  name="correoLog"
                  onChange={logeoDatos}
                />
                <input
                  type="password"
                  placeholder="Contraseña"
                  name="contraseñaLog"
                  onChange={logeoDatos}
                />
              </div>
              <input
                type="submit"
                className="logearse"
                value="INICIAR SESION"
              />
              <a href="/reset" className="olvideCuentaLink">
                Olvidaste tu usuario y/o contraseña?
              </a>
              {fromTotal ? (
                <div>
                  <h6>O</h6>
                  <h6>¿Quieres hacer una compra rápida?</h6>
                  <div className="fastRegister">
                    <p>Registro Rápido</p>
                  </div>
                </div>
              ) : (
                <button
                  className="crearCuentaLink"
                  onClick={formulario.registro}>
                  Crear cuenta
                  <FiArrowRightCircle />
                </button>
              )}
              <button
                className="google netButton"
                type="button"
                onClick={() => networkSignIn("google")}>
                <GrGoogle className="netIcon" /> Sign in with Google
              </button>

              <button
                className="facebook netButton"
                type="button"
                onClick={() => networkSignIn("facebook")}>
                <FaFacebookSquare className="netIcon" /> Sign in with Facebook
              </button>
              <CheckBoxTerms typeCheck={fromTotal} />
              {termsMessage && (
                <p className="termsMessage">
                  Acepte los terminos y condiciones
                </p>
              )}
            </form>
          ) : (
            /* FORMULARIO PARA REGISTRARSE */
            <form className="registerFormContainer" onSubmit={enviarDatos}>
              <h3>Registrarse</h3>
              <div className="camposDeRegister">
                <input
                  value={datosusuario.nombre}
                  type="text"
                  placeholder="Nombre y Apellido"
                  name="nombre"
                  autoComplete="off"
                  onChange={agregarDatos}
                  className="inputRegister"
                />
                <input
                  value={datosusuario.usuario}
                  type="text"
                  placeholder="Ususario"
                  name="usuario"
                  autoComplete="off"
                  onChange={agregarDatos}
                  className="inputRegister"
                />
                <input
                  value={datosusuario.correo}
                  type="email"
                  placeholder="correo"
                  name="correo"
                  autoComplete="off"
                  onChange={agregarDatos}
                  className="inputRegister"
                />
                <input
                  value={datosusuario.contraseña}
                  type="password"
                  placeholder="Contraseña"
                  name="contraseña"
                  autoComplete="off"
                  onChange={agregarDatos}
                  className="inputRegister"
                />
                <div className="pais">
                  <select
                    name="pais"
                    id="paises"
                    className="chooseCountry"
                    onChange={agregarDatos}>
                    <option disabled hidden defaultValue>
                      País
                    </option>
                    <option value="ad">AD</option>
                    <option value="ae">AE</option>
                    <option value="af">AF</option>
                    <option value="ag">AG</option>
                    <option value="ai">AI</option>
                    <option value="al">AL</option>
                    <option value="am">AM</option>
                    <option value="an">AN</option>
                    <option value="ao">AO</option>
                    <option value="aq">AQ</option>
                    <option value="ar">AR</option>
                    <option value="as">AS</option>
                    <option value="at">AT</option>
                    <option value="au">AU</option>
                    <option value="aw">AW</option>
                    <option value="ax">AX</option>
                    <option value="az">AZ</option>
                    <option value="ba">BA</option>
                    <option value="bb">BB</option>
                    <option value="bd">BD</option>
                    <option value="be">BE</option>
                    <option value="bf">BF</option>
                    <option value="bg">BG</option>
                    <option value="bh">BH</option>
                    <option value="bi">BI</option>
                    <option value="bj">BJ</option>
                    <option value="bl">BL</option>
                    <option value="bm">BM</option>
                    <option value="bn">BN</option>
                    <option value="bo">BO</option>
                    <option value="bq">BQ</option>
                    <option value="br">BR</option>
                    <option value="bs">BS</option>
                    <option value="bt">BT</option>
                    <option value="bv">BV</option>
                    <option value="bw">BW</option>
                    <option value="by">BY</option>
                    <option value="bz">BZ</option>
                    <option value="ca">CA</option>
                    <option value="cc">CC</option>
                    <option value="cd">CD</option>
                    <option value="cf">CF</option>
                    <option value="cg">CG</option>
                    <option value="ch">CH</option>
                    <option value="ci">CI</option>
                    <option value="ck">CK</option>
                    <option value="cl">CL</option>
                    <option value="cm">CM</option>
                    <option value="cn">CN</option>
                    <option value="co">CO</option>
                    <option value="cr">CR</option>
                    <option value="cu">CU</option>
                    <option value="cv">CV</option>
                    <option value="cw">CW</option>
                    <option value="cx">CX</option>
                    <option value="cy">CY</option>
                    <option value="cz">CZ</option>
                    <option value="de">DE</option>
                    <option value="dj">DJ</option>
                    <option value="dk">DK</option>
                    <option value="dm">DM</option>
                    <option value="do">DO</option>
                    <option value="dz">DZ</option>
                    <option value="ec">EC</option>
                    <option value="ee">EE</option>
                    <option value="eg">EG</option>
                    <option value="eh">EH</option>
                    <option value="er">ER</option>
                    <option value="es">ES</option>
                    <option value="et">ET</option>
                    <option value="eu">EU</option>
                    <option value="fi">FI</option>
                    <option value="fj">FJ</option>
                    <option value="fk">FK</option>
                    <option value="fm">FM</option>
                    <option value="fo">FO</option>
                    <option value="fr">FR</option>
                    <option value="ga">GA</option>
                    <option value="gb">GB</option>
                    <option value="gd">GD</option>
                    <option value="ge">GE</option>
                    <option value="gf">GF</option>
                    <option value="gg">GG</option>
                    <option value="gh">GH</option>
                    <option value="gi">GI</option>
                    <option value="gl">GL</option>
                    <option value="gm">GM</option>
                    <option value="gn">GN</option>
                    <option value="gp">GP</option>
                    <option value="gq">GQ</option>
                    <option value="gr">GR</option>
                    <option value="gs">GS</option>
                    <option value="gt">GT</option>
                    <option value="gu">GU</option>
                    <option value="gw">GW</option>
                    <option value="gy">GY</option>
                    <option value="hk">HK</option>
                    <option value="hm">HM</option>
                    <option value="hn">HN</option>
                    <option value="hr">HR</option>
                    <option value="ht">HT</option>
                    <option value="hu">HU</option>
                    <option value="id">ID</option>
                    <option value="ie">IE</option>
                    <option value="il">IL</option>
                    <option value="im">IM</option>
                    <option value="in">IN</option>
                    <option value="io">IO</option>
                    <option value="iq">IQ</option>
                    <option value="ir">IR</option>
                    <option value="is">IS</option>
                    <option value="it">IT</option>
                    <option value="je">JE</option>
                    <option value="jm">JM</option>
                    <option value="jo">JO</option>
                    <option value="jp">JP</option>
                    <option value="ke">KE</option>
                    <option value="kg">KG</option>
                    <option value="kh">KH</option>
                    <option value="ki">KI</option>
                    <option value="km">KM</option>
                    <option value="kn">KN</option>
                    <option value="kr">KR</option>
                    <option value="kw">KW</option>
                    <option value="ky">KY</option>
                    <option value="kz">KZ</option>
                    <option value="la">LA</option>
                    <option value="lb">LB</option>
                    <option value="lc">LC</option>
                    <option value="li">LI</option>
                    <option value="lk">LK</option>
                    <option value="lr">LR</option>
                    <option value="ls">LS</option>
                    <option value="lt">LT</option>
                    <option value="lu">LU</option>
                    <option value="lv">LV</option>
                    <option value="ly">LY</option>
                    <option value="ma">MA</option>
                    <option value="mc">MC</option>
                    <option value="md">MD</option>
                    <option value="me">ME</option>
                    <option value="mf">MF</option>
                    <option value="mg">MG</option>
                    <option value="mh">MH</option>
                    <option value="mk">MK</option>
                    <option value="ml">ML</option>
                    <option value="mm">MM</option>
                    <option value="mn">MN</option>
                    <option value="mo">MO</option>
                    <option value="mp">MP</option>
                    <option value="mq">MQ</option>
                    <option value="mr">MR</option>
                    <option value="ms">MS</option>
                    <option value="mt">MT</option>
                    <option value="mu">MU</option>
                    <option value="mv">MV</option>
                    <option value="mw">MW</option>
                    <option value="mx">MX</option>
                    <option value="my">MY</option>
                    <option value="mz">MZ</option>
                    <option value="na">NA</option>
                    <option value="nc">NC</option>
                    <option value="ne">NE</option>
                    <option value="nf">NF</option>
                    <option value="ng">NG</option>
                    <option value="ni">NI</option>
                    <option value="nl">NL</option>
                    <option value="no">NO</option>
                    <option value="np">NP</option>
                    <option value="nr">NR</option>
                    <option value="nu">NU</option>
                    <option value="nz">NZ</option>
                    <option value="om">OM</option>
                    <option value="pa">PA</option>
                    <option value="pe">PE</option>
                    <option value="pf">PF</option>
                    <option value="pg">PG</option>
                    <option value="ph">PH</option>
                    <option value="pk">PK</option>
                    <option value="pl">PL</option>
                    <option value="pm">PM</option>
                    <option value="pn">PN</option>
                    <option value="pr">PR</option>
                    <option value="ps">PS</option>
                    <option value="pt">PT</option>
                    <option value="pw">PW</option>
                    <option value="py">PY</option>
                    <option value="qa">QA</option>
                    <option value="re">RE</option>
                    <option value="ro">RO</option>
                    <option value="rs">RS</option>
                    <option value="ru">RU</option>
                    <option value="rw">RW</option>
                    <option value="sa">SA</option>
                    <option value="sb">SB</option>
                    <option value="sc">SC</option>
                    <option value="sd">SD</option>
                    <option value="se">SE</option>
                    <option value="sg">SG</option>
                    <option value="sh">SH</option>
                    <option value="si">SI</option>
                    <option value="sj">SJ</option>
                    <option value="sk">SK</option>
                    <option value="sl">SL</option>
                    <option value="sm">SM</option>
                    <option value="sn">SN</option>
                    <option value="so">SO</option>
                    <option value="sr">SR</option>
                    <option value="ss">SS</option>
                    <option value="st">ST</option>
                    <option value="sv">SV</option>
                    <option value="sx">SX</option>
                    <option value="sy">SY</option>
                    <option value="sz">SZ</option>
                    <option value="tc">TC</option>
                    <option value="td">TD</option>
                    <option value="tf">TF</option>
                    <option value="tg">TG</option>
                    <option value="th">TH</option>
                    <option value="tj">TJ</option>
                    <option value="tk">TK</option>
                    <option value="tl">TL</option>
                    <option value="tm">TM</option>
                    <option value="tn">TN</option>
                    <option value="to">TO</option>
                    <option value="tr">TR</option>
                    <option value="tt">TT</option>
                    <option value="tv">TV</option>
                    <option value="tw">TW</option>
                    <option value="tz">TZ</option>
                    <option value="ua">UA</option>
                    <option value="ug">UG</option>
                    <option value="um">UM</option>
                    <option value="us">US</option>
                    <option value="uy">UY</option>
                    <option value="uz">UZ</option>
                    <option value="va">VA</option>
                    <option value="vc">VC</option>
                    <option value="ve">VE</option>
                    <option value="vg">VG</option>
                    <option value="vi">VI</option>
                    <option value="vn">VN</option>
                    <option value="vu">VU</option>
                    <option value="wf">WF</option>
                    <option value="ws">WS</option>
                    <option value="xk">XK</option>
                    <option value="ye">YE</option>
                    <option value="yt">YT</option>
                    <option value="za">ZA</option>
                    <option value="zm">ZM</option>
                    <option value="zw">ZW</option>
                  </select>
                  <Flag code={datosusuario.pais} height="36" />
                </div>
                <div className="tipodecuenta">
                  <div>
                    <input
                      type="radio"
                      id="regular"
                      name="tipo"
                      value="regular"
                      onChange={agregarDatos}
                    />
                    <label htmlFor="regular">Usuario regular</label>
                  </div>
                  <div>
                    <input
                      type="radio"
                      id="vendedor"
                      name="tipo"
                      value="vendedor"
                      onChange={agregarDatos}
                    />
                    <label htmlFor="vendedor">Creador de contenido</label>
                  </div>
                </div>
                {datosusuario.tipo === "vendedor" ? (
                  <div className="datosvendedor">
                    <input
                      value={datosusuario.direction}
                      type="text"
                      placeholder="Dirección física actual"
                      className="direccion"
                      name="direction"
                      onChange={agregarDatos}
                    />
                    <div className="edad">
                      <input
                        type="date"
                        className="fecha"
                        name="edad"
                        onChange={agregarDatos}
                      />
                      <p className="numeroedad">{edad}</p>
                    </div>
                    {edad < 18 ? (
                      <p className="mensajeedad">
                        <GoAlert /> Lo sentimos, no puedes vender si eres menor
                        de edad.
                      </p>
                    ) : (
                      <Fragment />
                    )}
                    <input
                      type="email"
                      placeholder="correo de paypal para recibir pagos"
                      name="payemail"
                      onChange={agregarDatos}
                    />
                  </div>
                ) : (
                  <Fragment />
                )}
                <CheckBoxTerms />
              </div>
              <input
                type="submit"
                id="botoregistro"
                className="registrarse"
                value="REGISTRARSE"
                disabled={true}
              />
              <button className="entrarCuentaLink" onClick={formulario.logeo}>
                Tengo cuenta
                <FiArrowLeftCircle />
              </button>
            </form>
          )}
        </div>
      </div>
    </Fragment>
  );
}

export default Login;
