import React, { useEffect } from "react";
import { Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { obtenerProducts } from "../Redux/productos";
import "../Styles/Misposts.css";
import Posts from "./Posts";
import Circle from "./Circle";

function Misposts(props) {
  const dispatch = useDispatch();
  const misposts = useSelector((store) => store.products.products);
  const loaded = useSelector((store) => store.products.loading);
  const carrito = useSelector((store) => store.basket.basket);
  const user = useParams();

  const obtener = async () => {
    try {
      if (props.user) {
        dispatch(obtenerProducts(props.user));
      }
    } catch (err) {
      console.log(err);
    }
  };

  /* if(misposts === null || misposts === undefined){
        obtener()
    } */

  useEffect(() => {
    obtener();
  }, [props.user, user.usueractual]);

  return (
    <Fragment>
      <div className="posts">
        <h3 className="tituloPost">Posts</h3>
        {loaded ? (
          <Circle />
        ) : (
          <div className="misImagenes">
            {misposts !== undefined && misposts !== null ? (
              <>
                {misposts.length > 1 ? (
                  misposts.map((element) =>
                    element.id === "Basket" ||
                    element.id === "likes" ||
                    element.id === "history" ? null : (
                      <Posts
                        info={element}
                        own={props.own}
                        carrito={carrito}
                        key={element.id}
                      />
                    )
                  )
                ) : (
                  <h1 className="mensajeNoTienesProductos">
                    No tienes imagenes
                  </h1>
                )}
              </>
            ) : (
              <Circle />
            )}
          </div>
        )}
      </div>
    </Fragment>
  );
}

export default Misposts;
