import React, { useEffect, useState } from "react";
import "../Styles/Rutas.css";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import Restaurarpass from "./Restaurarpass";
import Allimages from "./Allimages";
import Header from "./Header";
import MainPage from "./MainPage";
import Basket from "./Basket";
import Barralateral from "./Barralateral";
import Subir from "./Subir";
import Condiciones from "./Condiciones";
import Privacidad from "./Privacidad";
import { auth } from "../Redux/firebase";
import Paneldeusuario from "./Paneldeusuario";
import Ajustes from "./Ajustes";
import Errorpage from "./Errorpage";
import Load from "./Load";
import Historial from "./Historial";
import Allusers from "./Allusers";
import Footer from "./Footer";
import Correo from "./Correo";
import Ventas from "./Ventas";
import Compras from "./Compras";

function Rutas() {
  const user = auth.currentUser;

  const datosuser = {
    rol: null,
  };
  const [usuariodata, setUsuariodata] = useState(datosuser);
  const [fireuser, setFireuser] = useState(false);

  useEffect(() => {
    detectarUser();
  }, [user]);

  const detectarUser = () => {
    auth.onAuthStateChanged((user) => {
      if (user) {
        setFireuser(user);
        user.getIdTokenResult().then((idTokenResult) => {
          if (!!idTokenResult.claims.admin) {
            datosuser.rol = idTokenResult.claims;
            setUsuariodata(datosuser);
            localStorage.setItem("rol", JSON.stringify(datosuser));
          } else if (!!idTokenResult.claims.autor) {
            datosuser.rol = idTokenResult.claims;
            setUsuariodata(datosuser);

            localStorage.setItem("rol", JSON.stringify(datosuser));
          } else {
            datosuser.rol = idTokenResult.claims;
            setUsuariodata(datosuser);

            localStorage.setItem("rol", JSON.stringify(datosuser));
          }
        });
      } else {
        setFireuser(null);
      }
    });
  };

  return fireuser !== false ? (
    <div>
      <Router>
        <Switch>
          <Route path="/cuenta/ajustes" exact>
            {user ? (
              <div className="perfilRuta">
                <Barralateral user={user} rol={usuariodata} />
                <Ajustes user={user} />
              </div>
            ) : (
              <Redirect to="/" />
            )}
          </Route>
          <Route path="/carrito" exact>
            <Header user={user} />
            <Basket />
          </Route>
          <Route path="/:usueractual/historial" exact>
            {user ? (
              <div className="perfilRuta">
                <Barralateral user={user} rol={usuariodata} />
                <Historial user={user} />
              </div>
            ) : (
              <Redirect to="/" />
            )}
          </Route>
          <Route path="/:usueractual/upload" exact>
            {user ? (
              <div className="perfilRuta">
                <Barralateral user={user} rol={usuariodata} />
                <Subir user={user} rol={usuariodata} />
              </div>
            ) : (
              <Redirect to="/" />
            )}
          </Route>
          <Route path="/terminos">
            <Condiciones />
          </Route>
          <Route path="/privacidad">
            <Privacidad />
          </Route>
          <Route path="/solicitudes" exact>
            {user ? (
              <div className="perfilRuta">
                <Barralateral user={user} rol={usuariodata} />
                <Allusers rol={usuariodata} />
              </div>
            ) : (
              <Redirect to="/" />
            )}
          </Route>
          <Route path="/ventas" exact>
            {user ? (
              <div className="perfilRuta">
                <Barralateral user={user} rol={usuariodata} />
                <Ventas rol={usuariodata} />
              </div>
            ) : (
              <Redirect to="/" />
            )}
          </Route>
          <Route path="/compras" exact>
            {user ? (
              <div className="perfilRuta">
                <Barralateral user={user} rol={usuariodata} />
                <Compras rol={usuariodata} />
              </div>
            ) : (
              <Redirect to="/" />
            )}
          </Route>
          <Route path="/reset" exact>
            {user ? (
              <Redirect to="/" />
            ) : (
              <>
                <Header />
                <Restaurarpass />
              </>
            )}
          </Route>
          <Route path="/Error" exact>
            <Errorpage />
          </Route>
          <Route path="/mail" exact>
            <Correo />
          </Route>
          <Route path="/:usueractual" exact>
            <Paneldeusuario user={user} rol={usuariodata} />
          </Route>
          <Route path="/" exact>
            <Header user={user} />
            <MainPage user={user} />
            <Allimages user={user} />
            <Footer />
          </Route>
        </Switch>
      </Router>
    </div>
  ) : (
    <Load main={true} />
  );
}

export default Rutas;
