import React from "react";
import emailjs from "emailjs-com";
import { AiOutlineCloseCircle } from "react-icons/ai";
import "../Styles/Correo.css";
import { useDispatch } from "react-redux";
import { loadanimation } from "../Redux/Cargas";
import { rechazar } from "../Redux/allUsers";

function Correo(props) {
  const dispatch = useDispatch();

  function emailrechazado(e) {
    e.preventDefault();

    loadanimation();
    emailjs
      .sendForm(
        "service_t1omwg9",
        "template_a89lqur",
        e.target,
        "user_4zKplIoNAg4oNAOAP3ECd"
      )
      .then(
        (result) => {
          dispatch(rechazar(props.info.usuario));
        },
        (error) => {
          console.log(error.text);
        }
      );
    e.target.reset();
  }

  return (
    <div className="correocontenedor">
      <AiOutlineCloseCircle
        className="closebut"
        onClick={() => props.close(false)}
      />
      <form className="contact-form" onSubmit={emailrechazado}>
        <input type="hidden" name="contact_number" />
        <div className="datosDeUsuario">
          <div>
            <label>Name</label>
            <input
              type="text"
              name="to_name"
              defaultValue={props.info.username}
            />
          </div>
          <div>
            <label>Email</label>
            <input
              type="email"
              name="user_email"
              defaultValue={props.info.correo}
            />
          </div>
        </div>
        <div className="AsuntoYMensaje">
          <div>
            <label>Asunto</label>
            <textarea
              name="asunto"
              defaultValue="Solicitud de verificación rechazada"
            />
          </div>
          <div>
            <label>Motivos</label>
            <textarea name="message" className="razoninput" rows="8" />
          </div>
        </div>
        <input type="submit" defaultValue="Rechazar" className="enviar" />
      </form>
    </div>
  );
}

export default Correo;
