import React, { Fragment } from "react";
import Barralateral from "./Barralateral";
import Usuario from "./Usuario";
import "../Styles/Rutas.css";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { obtendatos } from "../Redux/Login";
import Header from "./Header";

const Paneldeusuario = (props) => {
  const dispatch = useDispatch();
  const usuario = useParams();
  const usu = useSelector((store) => store.login.usuariodata);

  const obtenerdatosdeusuario = () => {
    dispatch(obtendatos(usuario.usueractual));
  };

  useEffect(() => {
    obtenerdatosdeusuario();
  }, [usuario.usueractual]);

  return (
    <Fragment>
      <div className="perfilRuta">
        {usu !== undefined && usu !== null ? (
          usu.state ? (
            <>
              <Barralateral rol={props.rol} />
              <Usuario user={usu.data} />
            </>
          ) : (
            <div className="perfilajeno">
              <Header />
              <Usuario
                admin={
                  props.rol.rol !== undefined && props.rol.rol !== null
                    ? props.rol.rol.admin
                    : false
                }
              />
            </div>
          )
        ) : (
          window.location.reload()
        )}
      </div>
    </Fragment>
  );
};

export default Paneldeusuario;

/* const administrar = (email)=>{
        //console.log(email)
       const agregar = functions.httpsCallable('agregarAdministrador')
        agregar({email: email})
            .then(res =>{
                console.log(res)
                if(res.data.error){
                    console.log('no tienes permisos')
                    return
                }
                db.collection('users').doc(user.uid).update({rol: 'autor'})
                    .then(user =>{
                        console.log('user modificado')
                    })
            })
    }
     */
