import { auth, db } from "./firebase";
import firebase from "firebase/app";
import { loadanimation, erroranimation, correctloaded } from "./Cargas";
import Swal from "sweetalert2";

const initianStateUser = {
    usuariodata: [],
};

const OBTENER_DATOS_USUARIO = "OBTENER_DATOS_USUARIO";

export default function reducerLogin(state = initianStateUser, action) {
    switch (action.type) {
        case OBTENER_DATOS_USUARIO:
            return {...state, usuariodata: action.payload };
        default:
            return state;
    }
}

export const logout = () => async() => {
    try {
        await auth.signOut();
        sessionStorage.removeItem("currentuser");
        localStorage.removeItem("rol");
        window.location.replace("/");
    } catch (error) {
        console.log(error);
    }
};

export const login = (datos) => async(dispatch, getState) => {
    var values = [],
        keys = Object.keys(localStorage),
        i = keys.length;

    while (i--) {
        if (keys[i].substring(0, 7) === "/basket") {
            values.push(localStorage.getItem(keys[i]));
        }
    }
    try {
        loadanimation();
        const user = await auth.signInWithEmailAndPassword(datos.correoLog, datos.contraseñaLog);
        if (values.length > 0) {
            const carrito = db.collection(user.user.uid).doc("Basket");
            values.forEach(async(item, index) => {
                let idItem = item.replaceAll(`"`, "")
                await carrito.update({
                    carrito: firebase.firestore.FieldValue.arrayUnion(idItem)
                })
                localStorage.removeItem(`/basket${idItem}`)
                if (index + 1 === values.length) {
                    await dispatch(obtendatos(user.user.uid))
                    window.location.reload();
                }
            });
        } else {
            await dispatch(obtendatos(user.user.uid))
            window.location.reload();
        }
    } catch (error) {
        if (error.code === "auth/invalid-email") {
            erroranimation("Correo invalido");
        } else if (error.code === "auth/user-not-found") {
            erroranimation("No se encuentra el usuario");
        } else if (error.code === "auth/wrong-password") {
            erroranimation("Contraseña incorrecta");
        } else if (error.code === "auth/network-request-failed") {
            erroranimation("Revisa la conexión a internet");
        } else {
            erroranimation(error.code);
        }
    }
};

//Esta funcion es para mandar a buscar los datos del usuario que se esta viendo su perfil
export const obtendatos = (current) => async(dispatch) => {
    //atributo current trae el username del usuario de quien se piden los datos
    try {
        loadanimation();
        const perfiluser = await db.collection("users").doc(current).get(); //Se busca en la DB el id del usuario por medio del username y se guarda en una variable (perfiluser)

        //Se verifica si el usuario existe
        if (perfiluser.exists) {
            //Si existe...
            const user = auth.currentUser; //Usuaio actualmente logeado

            //Si hay usuario
            if (user) {
                //Se compara si el id del usuario logeado es igual al del usuario del perfil que se esta visitando
                if (user.uid === perfiluser.data().unicid) {
                    //Se busca si los datos del usuario estan en el sessionstorage...
                    //En el caso de que esten, se buscan esos datos y se despachan en el array que se mostrara en el frontend
                    if (sessionStorage.getItem("currentuser")) {
                        await dispatch({
                            type: OBTENER_DATOS_USUARIO,
                            payload: JSON.parse(sessionStorage.getItem("currentuser")),
                        });
                        //Si no estan, se buscan en la DB y se guardan en el sessionstorage y se despachan en el array de datos del usuario
                    } else {
                        sessionStorage.setItem(
                            "currentuser",
                            JSON.stringify({ data: perfiluser.data(), state: true })
                        );
                        await dispatch({
                            type: OBTENER_DATOS_USUARIO,
                            payload: JSON.parse(sessionStorage.getItem("currectuser")),
                        });
                    }
                    //Si no coinciden los id's, quiere decir que no es el mismo usuario, por lo que se buscan los datos en la DB y se despachan los datos que puede ver el visitante en el array de datos de usuario
                } else {
                    await dispatch({
                        type: OBTENER_DATOS_USUARIO,
                        payload: perfiluser.data(),
                    });
                }
                //Si no hay user, Se hace el mismo procedimiento que si los id's no coincidieran
            } else {
                await dispatch({
                    type: OBTENER_DATOS_USUARIO,
                    payload: perfiluser.data(),
                });
            }
        }
    } catch (error) {
        console.log(error);
    } finally {
        Swal.close();
    }
};

export const recuperarcontraseña = (email) => async(dispatch, getState) => {
    try {
        loadanimation("Enviando correo");
        await auth.sendPasswordResetEmail(email);
        correctloaded("Enlace enviado, te llegara en unos minutos a tu correo!!");
    } catch (error) {
        console.log(error);
        if (error.code === "auth/user-not-found") {
            erroranimation(
                "No se encuentra el usuario, puede que haya sido eliminado"
            );
        } else if (error.code === "auth/too-many-requests") {
            erroranimation("Demasiados intentos, intenta de nuevo más tarde");
        } else {
            erroranimation();
        }
    }
};