import Swal from "sweetalert2";
import { loadanimation } from "./Cargas";
import { db } from "./firebase";

const initialState = {
  usuarios: [],
};

const OBTENER_VENTAS = "OBTENER_VENTAS";

export default function ventasReducer(state = initialState, action) {
  switch (action.type) {
    case OBTENER_VENTAS:
      return { ...state, usuarios: action.payload };
    default:
      return state;
  }
}

export const obtenerventas = () => async (dispatch) => {
  try {
    const ref = await db.collection("ventas").get();
    const data = ref.docs.map((el) => ({ id: el.id, ...el.data() }));
    dispatch({
      type: OBTENER_VENTAS,
      payload: data,
    });
  } catch (error) {
    console.log(error);
  }
};

export const confirmardeposito = (id) => async (dispatch) => {
  try {
    loadanimation();
    await db.collection("users").doc(id).update({
      gained: 0,
    });
    dispatch(obtenerventas());
    Swal.close();
  } catch (error) {
    console.log(error);
  }
};
