import React, { useEffect, useState } from "react";
import { Fragment } from "react";
import Flag from "react-world-flags";
import "../Styles/Usuario.css";
import InformacionDeUsuario from "./InformacionDeUsuario";
import Misposts from "./Misposts";
import { HiBadgeCheck } from "react-icons/hi";
import { MdTagFaces } from "react-icons/md";
import { obtendatos } from "../Redux/Login";
import { useDispatch, useSelector } from "react-redux";
import { useParams, Link } from "react-router-dom";
import Editfoto from "./Editfoto";
import { auth, db, functions } from "../Redux/firebase";

function Usuario(props) {
  const user = auth.currentUser;
  const dispatch = useDispatch();
  const usuario = useParams();
  const [editar, setEditar] = useState(false);
  const usu = useSelector((store) => store.login.usuariodata);
  const obtenerdatosdeusuario = async () => {
    try {
      const identific = await db
        .collection("usernames")
        .doc(usuario.usueractual)
        .get();
      if (identific.exists) {
        dispatch(obtendatos(identific.data().id));
      } else {
        window.location.replace("/Error");
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    obtenerdatosdeusuario();
  }, [usuario.usueractual]);

  if (user) {
    if (usu !== undefined && usu !== null) {
      if (usu.data !== undefined && usu.data !== null) {
        document.title = `${usu.data.name} | ${usu.data.username}`;
      } else {
        document.title = `${usu.name} | ${usu.username}`;
      }
    } else {
      document.title = "Cargando...";
    }
  } else {
    if (usu !== undefined && usu !== null) {
      document.title = `${usu.name} | ${usu.username}`;
    } else {
      document.title = "Cargando...";
    }
  }
  const cambio = {
    activar: () => {
      setEditar(true);
    },
    desactivar: () => {
      setEditar(false);
    },
  };

  return (
    <Fragment>
      {
        //Si soy yo
        props.user ? (
          <div className="perfil">
            <div className="fotoPerfil">
              {editar ? (
                <Editfoto
                  cerrar={cambio}
                  foto={usu.data.profile}
                  info={usu.data}
                />
              ) : null}
              <button className="foto" onClick={cambio.activar}>
                {usu.data.profile === null ? (
                  <MdTagFaces className="imagen2" />
                ) : (
                  <img src={usu.data.profile} alt="" className="imagen" />
                )}
              </button>
              <h3 className="nombreYapellido">{usu.data.name}</h3>
              <h4 className="userName">
                {usu.data.username}
                {usu.data.verified ? <HiBadgeCheck className="verify" /> : null}
              </h4>
              <Flag code={usu.data.country} className="pais" />
              <Link className="edit" to="/cuenta/ajustes">
                editar
              </Link>
            </div>
            <hr />
            <InformacionDeUsuario info={usu.data} own={true} />
            <hr />
            <Misposts user={usu.data.unicid} own={true} />
          </div>
        ) : (
          //Si estoy visitando el perfi
          <div className="perfil">
            <div className="fotoPerfil">
              <div className="foto">
                {usu.profile === null ? (
                  <MdTagFaces className="imagen3" />
                ) : (
                  <img src={usu.profile} alt="" className="imagen" />
                )}
              </div>
              <h3 className="nombreYapellido">{usu.name}</h3>
              <h4 className="userName">
                {usu.username}
                {usu.verified ? <HiBadgeCheck className="verify" /> : null}
              </h4>
              <Flag code={usu.country} className="pais" />
            </div>
            {props.admin ? (
              <>
                <hr />
                <InformacionDeUsuario info={usu} own={false} />
              </>
            ) : null}
            <hr />
            <Misposts user={usu.unicid} own={false} />
          </div>
        )
      }
    </Fragment>
  );
}

export default Usuario;
