import React from "react";
import { useEffect } from "react";
import priv from "../files/Política de privacidad.pdf";

function Privacidad() {
  useEffect(() => {
    document.title = "Políticas de privacidad";
  }, []);

  return (
    <div style={{ position: "absolute", width: "100%", height: "100%" }}>
      <object
        data={priv}
        type="application/pdf"
        width="100%"
        height="100%"></object>
    </div>
  );
}

export default Privacidad;
