import React from "react";
import "../Styles/Menudesplegable.css";
import { Fragment } from "react";
import { useState } from "react";
import {
  AiOutlineInstagram,
  AiOutlineWhatsApp,
  AiOutlineMail,
} from "react-icons/ai";
import { IoLocationOutline } from "react-icons/io5";
import { IoIosArrowUp, IoIosArrowDown } from "react-icons/io";
import Categorias from "./Categorias";
import { Link } from "react-router-dom";

function Menudesplegable() {
  const [menu, setMenu] = useState(false);
  const gopopular = () => {
    window.scrollTo(0, 600);
  };
  return (
    <Fragment>
      <div className="menu">
        <button onClick={() => setMenu(!menu)}>
          Explorar {menu ? <IoIosArrowUp /> : <IoIosArrowDown />}
        </button>
        {menu ? (
          <div className="menuopciones">
            <ul className="menumenu">
              <li className="mediatitle">Media</li>
              <Categorias />
              <li className="mediaitems">
                <button onClick={gopopular}>Populares</button>
              </li>
            </ul>
            <div className="empresainfo">
              <div className="acercadeconte">
                <ul className="acercade">
                  <li className="acercadetitle">Acerca de</li>
                  <li className="acercaitems">
                    <Link className="a" to="/terminos" target="_blank">
                      Terminos y condiciones
                    </Link>
                  </li>
                  <li className="acercaitems">
                    <Link className="a" to="/privacidad" target="_blank">
                      Políticas de privacidad
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="contactos">
                <ul className="redes">
                  <li className="redesitems">
                    <a
                      href="https://www.instagram.com/sublimat_bella/?hl=es"
                      target="_blank"
                      className="botondir"
                      rel="noreferrer">
                      <AiOutlineInstagram />
                      sublimat_bella
                    </a>
                  </li>
                  <li className="redesitems">
                    <div className="botondir">
                      <AiOutlineWhatsApp />
                      +507 6596-9975
                    </div>
                  </li>
                  <li className="redesitems">
                    <div className="botondir">
                      <AiOutlineMail />
                      soporte@sublimatbella.com
                    </div>
                  </li>
                  <li className="redesitems">
                    <a
                      href="https://www.google.com/maps/place/Sublimat+%26+Dise%C3%B1os+Bella/@8.8761585,-79.7943664,15z/data=!4m5!3m4!1s0x0:0xa59ca047ece886a7!8m2!3d8.8761591!4d-79.7943726"
                      target="_blank"
                      className="botondir"
                      rel="noreferrer">
                      <IoLocationOutline />
                      Ubicación
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </Fragment>
  );
}

export default Menudesplegable;
