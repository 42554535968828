import Swal from "sweetalert2";
import { alerta, correctloaded, erroranimation, loadanimation } from "./Cargas";
import { auth, db } from "./firebase";
import firebase from "firebase/app";
import moment from "moment";

const actualizarEmail = async (user, email) => {
  const hoy = moment();
  try {
    const { value: password } = await Swal.fire({
      title: "Introducir contraseña",
      input: "password",
      inputLabel:
        "Por asuntos de seguridad, para cambiar el correo electronico, solicitamos que introduzca su contraseña",
      inputPlaceholder: "Contraseña",
      inputAttributes: {
        autocapitalize: "off",
        autocorrect: "off",
      },
    });
    if (password) {
      try {
        auth
          .signInWithEmailAndPassword(user.email, password)
          .then(function (userCredential) {
            userCredential.user.updateEmail(email);
          })
          .catch((error) => {
            if (error.code === "auth/wrong-password") {
              erroranimation("Contraseña incorrecta");
            } else if (error.code === "auth/network-request-failed") {
              erroranimation("Revisa la conexión a internet");
            } else {
              erroranimation(error.code);
            }
          });
      } catch (error) {
        if (error.code === "auth/wrong-password") {
          erroranimation("Contraseña incorrecta");
        } else if (error.code === "auth/network-request-failed") {
          erroranimation("Revisa la conexión a internet");
        } else {
          erroranimation(error.code);
        }
      }
    } else {
      alerta("Sin contraseña no puedes actualizar el correo, lo sentimos :(");
    }
  } catch (error) {
    if (error.code === "auth/wrong-password") {
      erroranimation("Contraseña incorrecta");
    } else if (error.code === "auth/network-request-failed") {
      erroranimation("Revisa la conexión a internet");
    } else {
      erroranimation(error.code);
    }
  }
};

const actualizarUsername = async (user, username, actual) => {
  const hoy = moment();
  try {
    //const actualuser = await db.collection('users').doc(user.uid).get()
    //const usernam = actualuser.data().username
    const res = await db.collection("usernames").doc(username).get();
    if (res.exists) {
      alerta("Nombre de Usuario ya en uso");
    } else {
      await db
        .collection("usernames")
        .doc(username)
        .set({
          id: user.uid,
        })
        .then(async () => {
          await db
            .collection("usernames")
            .doc(actual)
            .delete()
            .then(async () => {
              await db
                .collection("users")
                .doc(user.uid)
                .update({
                  username: username,
                })
                .then(async () => {
                  const res2 = await db.collection("users").doc(user.uid).get();
                  sessionStorage.setItem(
                    "currentuser",
                    JSON.stringify({ data: res2.data(), state: true })
                  );
                  correctloaded();
                });
            })
            .catch((error) => {
              console.log(error);
            });
        })
        .catch((error) => {
          console.log(error);
        });
    }
  } catch (error) {
    console.log(error);
  }
};

const actualizarPassword = async (user, Password, newPassword) => {
  const hoy = moment();
  try {
    try {
      auth
        .signInWithEmailAndPassword(user.email, Password)
        .then(function (userCredential) {
          userCredential.user.updatePassword(newPassword);
        })
        .then(function () {
          correctloaded("se cambio con exito");
        })
        .catch((error) => {
          if (error.code === "auth/wrong-password") {
            erroranimation("Contraseña incorrecta");
          } else if (error.code === "auth/network-request-failed") {
            erroranimation("Revisa la conexión a internet");
          } else {
            erroranimation(error.code);
          }
        });
    } catch (error) {
      if (error.code === "auth/wrong-password") {
        erroranimation("Contraseña incorrecta");
      } else if (error.code === "auth/network-request-failed") {
        erroranimation("Revisa la conexión a internet");
      } else {
        erroranimation(error.code);
      }
    }
  } catch (error) {
    if (error.code === "auth/wrong-password") {
      erroranimation("Contraseña incorrecta");
    } else if (error.code === "auth/network-request-failed") {
      erroranimation("Revisa la conexión a internet");
    } else {
      erroranimation(error.code);
    }
  }
};

export const actualizar = async (datos, user, actual) => {
  const hoy = moment();
  const historiahoy = db
    .collection(user.uid)
    .doc("history")
    .collection("historial")
    .doc(hoy.format("MM-DD-YYYY"));
  try {
    loadanimation();
    const usuario = db.collection("users").doc(user.uid);
    for (const dato in datos) {
      if (dato === "email") {
        await actualizarEmail(user, datos[dato]);
        const historiahoyver = await historiahoy.get();
        if (historiahoyver.exists) {
          await historiahoy.update({
            historial: firebase.firestore.FieldValue.arrayUnion({
              mensaje: "Actualizaste el email",
            }),
          });
        } else {
          await db
            .collection(user.uid)
            .doc("history")
            .collection("historial")
            .doc(hoy.format("MM-DD-YYYY"))
            .set({ historial: [{ mensaje: "Actualizaste el email" }] });
        }
      } else if (dato === "username") {
        await actualizarUsername(user, datos[dato], actual);
        const historiahoyver = await historiahoy.get();
        if (historiahoyver.exists) {
          await historiahoy.update({
            historial: firebase.firestore.FieldValue.arrayUnion({
              mensaje: "Actualizaste el nombre de usuario",
            }),
          });
        } else {
          await db
            .collection(user.uid)
            .doc("history")
            .collection("historial")
            .doc(hoy.format("MM-DD-YYYY"))
            .set({
              historial: [{ mensaje: "Actualizaste el nombre de usuario" }],
            });
        }
      } else if (dato === "password") {
        await actualizarPassword(user, datos[dato], datos.newpassword);
        const historiahoyver = await historiahoy.get();
        if (historiahoyver.exists) {
          await historiahoy.update({
            historial: firebase.firestore.FieldValue.arrayUnion({
              mensaje: "Actualizaste la contraseña",
            }),
          });
        } else {
          await db
            .collection(user.uid)
            .doc("history")
            .collection("historial")
            .doc(hoy.format("MM-DD-YYYY"))
            .set({ historial: [{ mensaje: "Actualizaste la contraseña" }] });
        }
      } else if (dato === "name") {
        await usuario
          .update({
            name: datos[dato],
          })
          .then(async () => {
            const res = await db.collection("users").doc(user.uid).get();
            sessionStorage.setItem(
              "currentuser",
              JSON.stringify({ data: res.data(), state: true })
            );
            const historiahoyver = await historiahoy.get();
            if (historiahoyver.exists) {
              await historiahoy.update({
                historial: firebase.firestore.FieldValue.arrayUnion({
                  mensaje: "Actualizaste el nombre",
                }),
              });
            } else {
              await db
                .collection(user.uid)
                .doc("history")
                .collection("historial")
                .doc(hoy.format("MM-DD-YYYY"))
                .set({ historial: [{ mensaje: "Actualizaste el nombre" }] });
            }
            correctloaded("Se actualizo el nombre");
          });
      } else if (dato === "direction") {
        await usuario
          .update({
            direction: datos[dato],
          })
          .then(async () => {
            const res = await db.collection("users").doc(user.uid).get();
            sessionStorage.setItem(
              "currentuser",
              JSON.stringify({ data: res.data(), state: true })
            );
            const historiahoyver = await historiahoy.get();
            if (historiahoyver.exists) {
              await historiahoy.update({
                historial: firebase.firestore.FieldValue.arrayUnion({
                  mensaje: "Actualizaste la direccion",
                }),
              });
            } else {
              await db
                .collection(user.uid)
                .doc("history")
                .collection("historial")
                .doc(hoy.format("MM-DD-YYYY"))
                .set({ historial: [{ mensaje: "Actualizaste la direccion" }] });
            }
            correctloaded("Se actualizo la direccion");
          });
      } else if (dato === "payemail") {
        await usuario
          .update({
            payemail: datos[dato],
          })
          .then(async () => {
            const res = await db.collection("users").doc(user.uid).get();
            sessionStorage.setItem(
              "currentuser",
              JSON.stringify({ data: res.data(), state: true })
            );
            const historiahoyver = await historiahoy.get();
            if (historiahoyver.exists) {
              await historiahoy.update({
                historial: firebase.firestore.FieldValue.arrayUnion({
                  mensaje: "Actualizaste el correo de pago",
                }),
              });
            } else {
              await db
                .collection(user.uid)
                .doc("history")
                .collection("historial")
                .doc(hoy.format("MM-DD-YYYY"))
                .set({
                  historial: [{ mensaje: "Actualizaste el correo de pago" }],
                });
            }
            correctloaded("Se actualizo el correo de pago");
          });
      } else if (dato === "country") {
        await usuario
          .update({
            country: datos[dato],
          })
          .then(async () => {
            const res = await db.collection("users").doc(user.uid).get();
            sessionStorage.setItem(
              "currentuser",
              JSON.stringify({ data: res.data(), state: true })
            );

            const historiahoyver = await historiahoy.get();
            if (historiahoyver.exists) {
              await historiahoy.update({
                historial: firebase.firestore.FieldValue.arrayUnion({
                  mensaje: "Actualizaste el pais",
                }),
              });
            } else {
              await db
                .collection(user.uid)
                .doc("history")
                .collection("historial")
                .doc(hoy.format("MM-DD-YYYY"))
                .set({ historial: [{ mensaje: "Actualizaste el pais" }] });
            }
            correctloaded("Se actualizo el pais");
          });
      }
    }
    window.location.reload();
  } catch (error) {
    console.log(error);
  }
};
