import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import Venta from "./Venta";
import "../Styles/Ventas.css";
import { obtenerventas } from "../Redux/ventas";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
//import { db } from "../Redux/firebase";

function Ventas({ rol }) {
  const [loadingRol, setLoadingRol] = useState(true);
  const [rolAdmin, setRolAdmin] = useState(false);
  const ventastore = useSelector((store) => store.ventas.usuarios);
  const dispatch = useDispatch();

  useEffect(() => {
    document.title = "Admin - Ventas";
    buscarventas();
  }, []);

  const buscarventas = () => {
    dispatch(obtenerventas());
  };

  useEffect(() => {
    if (rol.rol.admin) {
      setLoadingRol(false);
      setRolAdmin(true);
    } else {
      setLoadingRol(false);
      setRolAdmin(false);
    }
  }, [rol.rol]);

  if (loadingRol) return <h1>Loading...</h1>;

  return rolAdmin ? (
    <div className="ventascontainer">
      <h4>Transacciones por hacer este mes</h4>
      {ventastore !== undefined &&
        ventastore !== null &&
        (ventastore.length > 0 ? (
          ventastore.map((i) => <Venta ventasdatos={i} key={i.id} />)
        ) : (
          <h3>Hubo un error, recargue la paguina</h3>
        ))}
    </div>
  ) : (
    <Redirect to="/" />
  );
}

export default Ventas;
