import React, { Fragment } from "react";
import { useState } from "react";
import { useEffect } from "react";
import "../Styles/MainPage.css";
import PopUp from "./PopUp";

function MainPage({ user }) {
  const [showPopUp, setShowPopUp] = useState(false);

  useEffect(() => {
    document.title = "Sublimat y Diseños Bella";
  }, []);

  useEffect(() => {
    const showModalPopUp = JSON.parse(localStorage.getItem("notShowPopUp"));
    if (showModalPopUp) {
      setShowPopUp(false);
    } else {
      if (user) {
        setShowPopUp(false);
      } else {
        setShowPopUp(true);
      }
    }
  }, [user]);

  return (
    <Fragment>
      {showPopUp && <PopUp setShowPopUp={setShowPopUp} />}
      <div className="portada">
        <h1>SUBLIMAT Y DISEÑOS BELLA</h1>
        <p className="mensaje">
          Te recuerda que: "No necesitas ser un experto para ser un gran
          artista"
        </p>
        <i className="artista">- Rui Torres (Art Attack)</i>
      </div>
    </Fragment>
  );
}

export default MainPage;
