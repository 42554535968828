import React from 'react'
import '../Styles/Load.css'

function Circle() {
    return (
        <div className='contecarga'>
            <div className='circulo'>
                
            </div>
        </div>
    )
}

export default Circle
