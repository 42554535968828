import Swal from "sweetalert2"
import { loadanimation } from "./Cargas"
import { storage, auth, db } from "./firebase"
import { obtendatos } from "./Login"

export const editarImagen = (imagen) => async(dispatch, getState) => {

    const user = auth.currentUser
    try {
        loadanimation()
        const imagenRef = await storage.ref().child(user.uid).child('perfil')
        await imagenRef.put(imagen)
        const imagenURL = await imagenRef.getDownloadURL()
        await db.collection('users').doc(user.uid).update({
            profile: imagenURL
        })
        const res = await db.collection('users').doc(user.uid).get()
        sessionStorage.setItem('currentuser', JSON.stringify({ 'data': res.data(), 'state': true }))
    } catch (error) {
        console.log(error)
    } finally {
        dispatch(obtendatos())
        Swal.close()
    }
}

export const eliminarImagen = () => async(dispatch, getState) => {
    const user = auth.currentUser
    try {
        loadanimation()
        await db.collection('users').doc(user.uid).update({
            profile: null
        })
        const res = await db.collection('users').doc(user.uid).get()
        sessionStorage.setItem('currentuser', JSON.stringify({ 'data': res.data(), 'state': true }))
        const imagenRef = await storage.ref().child(user.uid).child('perfil')
        await imagenRef.delete().then(function() {
            dispatch(obtendatos())
            Swal.close()
        }).catch(function(error) {
            console.log(error)
        });
    } catch (error) {
        console.log(error)
    }
}