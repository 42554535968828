import { createStore, combineReducers, compose, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import reducerAllproducts from "./Allimages";
import solicitudReducer from "./allUsers";
import reducerAgregarcarrito from "./basket";
import comprasReducer from "./compras";
import headerdatosReducer from "./Headerdata";
import historialReducer from "./historial";
import linkeReducer from "./Like";
import reducerLogin from "./Login";
import reducerProducts from "./productos";
import reducerGoogleLogin, { reducerFacebookLogin } from "./registro";
import ventasReducer from "./ventas";

const reducer = combineReducers({
    googleLogin: reducerGoogleLogin,
    facebookLogin: reducerFacebookLogin,
    login: reducerLogin,
    allprod: reducerAllproducts,
    products: reducerProducts,
    basket: reducerAgregarcarrito,
    likes: linkeReducer,
    historial: historialReducer,
    solicitudes: solicitudReducer,
    headerdato: headerdatosReducer,
    ventas: ventasReducer,
    compras: comprasReducer
});

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export default function generateStore() {
    const store = createStore(reducer, composeEnhancers(applyMiddleware(thunk)));
    return store;
}