import React, { useEffect, useState } from "react";
import { Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { obtenercarrito } from "../Redux/basket";
import "../Styles/Basket.css";
import Total from "./Total";
import BasketItem from "./BasketItem";
import { auth } from "../Redux/firebase";

function Basket() {
  const user = auth.currentUser;
  const dispatch = useDispatch();
  const [licenceChecked, setLicenceChecked] = useState(0);
  const [allLicencesChecked, setAllLicencesChecked] = useState(false);
  const precios = [];
  var suma = 0;

  useEffect(() => {
    if (carrito && carrito.carrito) {
      if (licenceChecked >= carrito.carrito.length) {
        setAllLicencesChecked(true);
      } else {
        setAllLicencesChecked(false);
      }
    }
    //eslint-disable-next-line
  }, [licenceChecked]);

  const addLicenceChecked = () => {
    setLicenceChecked(licenceChecked + 1);
  };

  const carrito = useSelector((store) => store.basket.basket);

  return (
    <Fragment>
      <div className="basket">
        <div className="itemsbas">
          {user
            ? carrito.carrito !== undefined &&
              (carrito.carrito.length > 0 ? (
                carrito.carrito
                  .slice(0)
                  .reverse()
                  .map((i) => (
                    <div key={i.imageid}>
                      <BasketItem
                        id={i}
                        licencesChecked={licenceChecked}
                        addLicenceChecked={addLicenceChecked}
                      />
                      <div className="acumulacion">{precios.push(i)}</div>
                    </div>
                  ))
              ) : (
                <h1 className="mensajenohay">
                  No tienes nada guardado en el carrito
                </h1>
              ))
            : carrito !== undefined &&
              (carrito.length > 0 ? (
                carrito
                  .slice(0)
                  .reverse()
                  .map((i) => (
                    <>
                      <BasketItem id={JSON.parse(i)} key={i.imageid} />
                    </>
                  ))
              ) : (
                <h1 className="mensajenohay">
                  No tienes nada guardado en el carrito
                </h1>
              ))}
        </div>
        <div className="totalconte">
          <>
            <Total
              precios={precios}
              allLicencesChecked={allLicencesChecked}
              finalTotal={true}
            />
          </>
        </div>
      </div>
    </Fragment>
  );
}

export default Basket;
