import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { db } from "../Redux/firebase";
import { confirmardeposito } from "../Redux/ventas";
import "../Styles/Venta.css";

function Venta(props) {
  const [ventasdatos, setVentasdatos] = useState(null);
  const dispatch = useDispatch();
  useEffect(() => {
    if (props) {
      fetchventas(props.ventasdatos.id);
    }
  }, [props]);

  const fetchventas = async (ref) => {
    const datos = await db.collection("users").doc(ref).get();
    setVentasdatos(datos.data());
  };

  return (
    <>
      {ventasdatos !== null ? (
        ventasdatos.gained > 0 ? (
          <div className="transaccionitem">
            <div className="perfilventa">
              {ventasdatos.profile !== null ? (
                <div
                  style={{
                    backgroundImage: `url(${ventasdatos.profile})`,
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                  }}></div>
              ) : (
                <div style={{ border: "solid 2px black" }}></div>
              )}
            </div>
            <div className="userinfo">
              <h3>{ventasdatos.username}</h3>
              <h1>{ventasdatos.payemail}</h1>
            </div>
            <div className="saldo">
              <h3>{ventasdatos.gained}</h3>
              <button
                onClick={() => {
                  dispatch(confirmardeposito(props.ventasdatos.id));
                }}>
                Listo!
              </button>
            </div>
          </div>
        ) : null
      ) : (
        <h3>Loading...</h3>
      )}
    </>
  );
}

export default Venta;
