import React from 'react'
import agua from '../Imagenes/Nuevo Logo oficial de Sublimat.png'
import '../Styles/Load.css'

function Load(props) {
    return (
        (props.main)?(
            <div className='cargas'>
                <img  src={agua} alt="" className='loading'/>
            </div>
        ):(
            <div className='cargasdos'>
                <img  src={agua} alt="" className='loading'/>
            </div>
        )
    )
}

export default Load
