import React, { Fragment, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { obtenerallproducts } from "../Redux/Allimages";
import { buscarlikes } from "../Redux/Like";
import Masvendidas from "./Masvendidas";
import "../Styles/Allimages.css";
import Posts from "./Posts";

function Allimages(props) {
  const dispatch = useDispatch();
  const productos = useSelector((store) => store.allprod.products);
  const likes = useSelector((store) => store.likes.likes);

  useEffect(() => {
    dispatch(obtenerallproducts());
    dispatch(buscarlikes());
  }, [props.user]);

  return (
    <Fragment>
      <div className="imagenes">
        <Masvendidas products={productos} user={props.user} likes={likes} />
        <div className="all_items">
          {productos.length > 0 ? (
            productos.map((i) => (
              <Fragment key={i.imageid}>
                <Posts
                  info={i}
                  own={false}
                  user={props.user}
                  likes={likes.includes(i.imageid) ? true : false}
                />
              </Fragment>
            ))
          ) : (
            <h1>No hay productos o hubo un error</h1>
          )}
        </div>
      </div>
    </Fragment>
  );
}

export default Allimages;
