import React from "react";
import { Provider } from "react-redux";
import generateStore from "./Redux/store";
import Rutas from "./Components/Rutas";

function App() {
  const store = generateStore();

  return (
    <Provider store={store}>
      <Rutas />
    </Provider>
  );
}

export default App;
