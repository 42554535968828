import app from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'
import 'firebase/storage'
import 'firebase/functions'
import firebase from 'firebase/app'

const firebaseConfig = {
    apiKey: "AIzaSyBu3lsiUaiNFIrWq796qMq1ksCPpgwAa3I",
    authDomain: "sublimat-web.firebaseapp.com",
    projectId: "sublimat-web",
    storageBucket: "sublimat-web.appspot.com",
    messagingSenderId: "827572131170",
    appId: "1:827572131170:web:c288592789f5f0942a083b",
    measurementId: "G-4PHL9FPQ7H"
};

app.initializeApp(firebaseConfig);

const db = app.firestore()
const auth = app.auth()
const storage = app.storage()
const functions = app.functions()

const googleAuthProvider = new firebase.auth.GoogleAuthProvider();
const facebookAuthProvider = new firebase.auth.FacebookAuthProvider();


export { db, auth, app, functions, storage, googleAuthProvider, facebookAuthProvider, firebase }