import Swal from "sweetalert2";
import { alerta, loadanimation } from "./Cargas";
import { auth, db, storage } from "./firebase";
import moment from "moment";
import firebase from "firebase/app";
//import { eliminarcarrito } from "./basket";

export const descargar = (id, user) => async(dispatch) => {
    const useractu = auth.currentUser;
    const imageid = id;
    const hoy = moment();
    try {
        //Si hay usuario
        if (useractu) {
            loadanimation("Porfavor, espera a que se cierre esta pantalla");
            const postRef = storage
                .ref(user)
                .child("posts")
                .child(imageid)
                .child("Gracias por preferirnos ;).zip"); //Referencia al zip en el storage
            const rutadearchivo = await postRef.getDownloadURL(); //Obtener el link de descarga
            window.open(rutadearchivo, "_blank"); //Descarga de archivo

            //Historial propio, obtener la fecha actual
            const historiahoy = db
                .collection(useractu.uid)
                .doc("history")
                .collection("historial")
                .doc(hoy.format("MM-DD-YYYY"));
            const historiahoyver = await historiahoy.get();
            //Si la fecha existe
            if (historiahoyver.exists) {
                await historiahoy.update({
                    historial: firebase.firestore.FieldValue.arrayUnion({
                        mensaje: `Descargaste: ${imageid}`,
                    }),
                });
                //Si la fecha no existe
            } else {
                await db
                    .collection(useractu.uid)
                    .doc("history")
                    .collection("historial")
                    .doc(hoy.format("MM-DD-YYYY"))
                    .set({ historial: [{ mensaje: `Descargaste: ${imageid}` }] });
            }

            //Historial del autor, obtener la fecha actual
            const historiahoyautor = db
                .collection(user)
                .doc("history")
                .collection("historial")
                .doc(hoy.format("MM-DD-YYYY"));
            const historiahoyverautor = await historiahoyautor.get();
            //Si la fecha existe
            if (historiahoyverautor.exists) {
                await historiahoyautor.update({
                    historial: firebase.firestore.FieldValue.arrayUnion({
                        mensaje: `Vendiste: ${imageid}`,
                    }),
                });
                //Si la fecha no existe
            } else {
                await db
                    .collection(user)
                    .doc("history")
                    .collection("historial")
                    .doc(hoy.format("MM-DD-YYYY"))
                    .set({ historial: [{ mensaje: `Vendiste: ${imageid}` }] });
            }

            //Aumentar cantidad de descargas
            const ref = await db.collection("AllProducts").doc(imageid).get();
            const original = await ref.data().autor;
            //Si la imagen existe
            await db
                .collection("AllProducts")
                .doc(imageid)
                .update({
                    downloads: (await ref.data().downloads) + 1,
                });
            await db
                .collection(original)
                .doc(imageid)
                .update({
                    downloads: (await ref.data().downloads) + 1,
                });
            const precio = (
                await db.collection("AllProducts").doc(imageid).get()
            ).data().price;

            //Suma de nuevo ganado
            const capital = (await db.collection("users").doc(user).get()).data()
                .gained;
            await db
                .collection("users")
                .doc(user)
                .update({
                    gained: capital + parseInt(precio),
                });
            Swal.close();
            //Si no hay usuario
        } else {
            alerta("Inicia Sesion");
        }
    } catch (error) {
        Swal.close();
        console.log(error);
    }
};

export const confirmar =
    (...item) =>
    async(dispatch) => {
        try {
            loadanimation();
            await item[0].map(async(element) => {
                const res = await db.collection("AllProducts").doc(element).get();
                await dispatch(descargar(element, res.data().autor));
            });
        } catch (error) {
            console.log(error);
            Swal.close();
        }
    };