import React from "react";
import { auth } from "../Redux/firebase";
import { BiUser } from "react-icons/bi";
import { RiShoppingCartLine } from "react-icons/ri";
import { CgCloseO } from "react-icons/cg";
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { headerdata } from "../Redux/Headerdata";
import { useEffect } from "react";
import Login from "./Login";
import "../Styles/Btnsresponsive.css";
import { obtenercarrito } from "../Redux/basket";
import Menudesplegable from "./Menudesplegable";
import {
  AiOutlineInstagram,
  AiOutlineWhatsApp,
  AiOutlineMail,
} from "react-icons/ai";
import { IoLocationOutline } from "react-icons/io5";
import { IoIosArrowUp, IoIosArrowDown } from "react-icons/io";
import Categorias from "./Categorias";

function BtnsResponsive(props) {
  const user = auth.currentUser;
  const histo = useHistory();
  const gopopular = () => {
    window.scrollTo(0, 600);
  };

  function open() {
    if (user) {
      window.location.replace(`/${props.usuariodat}`);
    } else {
      var mostrarLogin = document.getElementById("ventanaLogin");
      mostrarLogin.style.display = "block";
      document.getElementById("menuheader").checked = false;
    }
  }
  function cerrar() {
    var mostrarLogin = document.getElementById("ventanaLogin");
    mostrarLogin.style.display = "none";
  }
  return (
    <>
      <ul className="opcionesdemenu">
        <button className="a" onClick={open}>
          {user ? (
            <p>
              <BiUser className="icono2" /> Mi cuenta
            </p>
          ) : (
            <p>
              <BiUser className="icono" /> Mi cuenta
            </p>
          )}
        </button>
        <button
          className="a"
          onClick={() => {
            histo.push("/carrito");
            document.getElementById("menuheader").checked = false;
          }}>
          <p>
            {props.carrito !== undefined ? (
              props.carrito.carrito !== undefined ? (
                <>
                  <RiShoppingCartLine className="icono" />
                  {props.carrito.carrito.length}
                </>
              ) : (
                <>
                  {" "}
                  <RiShoppingCartLine className="icono" /> 0
                </>
              )
            ) : (
              <>
                {" "}
                <RiShoppingCartLine className="icono" /> 0
              </>
            )}
          </p>
        </button>
        <li>
          <ul className="mediainfo">
            <li className="mediatitle">Media</li>
            <Categorias />
            <li className="mediaitems">
              <button onClick={gopopular}>Populares</button>
            </li>
          </ul>
        </li>
        <li>
          <ul className="acercade">
            <li className="acercadetitle">Acerca de</li>
            <li className="acercaitems">
              <Link className="a" to="/terminos" target="_blank">
                Terminos y condiciones
              </Link>
            </li>
            <li className="acercaitems">
              <Link className="a" to="/privacidad" target="_blank">
                Políticas de privacidad
              </Link>
            </li>
          </ul>
        </li>
        <li>
          <ul className="redes">
            <li className="redestitle">Redes</li>
            <li className="redesitems">
              <a
                href="https://www.instagram.com/sublimat_bella/?hl=es"
                target="_blank"
                className="botondir"
                rel="noreferrer">
                <AiOutlineInstagram />
                sublimat_bella
              </a>
            </li>
            <li className="redesitems">
              <div className="botondir">
                <AiOutlineWhatsApp />
                +507 6596-9975
              </div>
            </li>
            <li className="redesitems">
              <div className="botondir">
                <AiOutlineMail />
                soporte@sublimatbella.com
              </div>
            </li>
            <li className="redesitems">
              <a
                href="https://www.google.com/maps/place/Sublimat+%26+Dise%C3%B1os+Bella/@8.8761585,-79.7943664,15z/data=!4m5!3m4!1s0x0:0xa59ca047ece886a7!8m2!3d8.8761591!4d-79.7943726"
                target="_blank"
                className="botondir"
                rel="noreferrer">
                <IoLocationOutline />
                Ubicación
              </a>
            </li>
          </ul>
        </li>
      </ul>
      <div className="popUpDeLogin" id="ventanaLogin">
        <button className="close" onClick={cerrar}>
          <CgCloseO />
        </button>
        <Login />
      </div>
    </>
  );
}

export default BtnsResponsive;
