import React from "react";
import { useEffect } from "react";
import ter from "../files/Terminos y Condiciones Oficial.pdf";

function Condiciones() {
  useEffect(() => {
    document.title = "Terminos y Condiciones";
  }, []);

  return (
    <div style={{ position: "absolute", width: "100%", height: "100%" }}>
      <object
        data={ter}
        type="application/pdf"
        width="100%"
        height="100%"></object>
    </div>
  );
}

export default Condiciones;
