import { auth, db } from "./firebase"


const initialState = {
    historia: []
}

const HISTORIAL = 'HISTORIAL'


export default function historialReducer (state = initialState, action){
    switch(action.type){
        case HISTORIAL:
            return {...state, historia: action.payload }
        default:
            return state
    }
}

export const historial = ()=>async(dispatch)=>{
    const user = auth.currentUser
    try{
        const data = await db.collection(user.uid).doc('history').collection('historial').get()
        const arrhistory = data.docs.map(el=>({id: el.id, ...el.data()}))
        dispatch({
            type: HISTORIAL,
            payload: arrhistory
        })
    }catch(error){
        console.log(error)
    }
}