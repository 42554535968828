import React from "react";
import "../Styles/Footer.css";
import {
  AiOutlineInstagram,
  AiOutlineWhatsApp,
  AiOutlineMail,
} from "react-icons/ai";
import { IoLocationOutline } from "react-icons/io5";
import logo from "../Imagenes/Nuevo Logo oficial de Sublimat (inicio).png";
import { Link } from "react-router-dom";

function Footer() {
  const backToTop = () => {
    window.scrollTo(0, 0);
  };
  return (
    <footer className="piedepagina">
      <div className="informacionpiedepagina">
        <div className="logo">
          <button onClick={backToTop}>
            <img src={logo} alt="logo de sublimat" />
          </button>
        </div>
        <div className="redeslink">
          <div className="contactos">
            <h4>Contacto</h4>
            <ul className="redes">
              <li className="redesitems">
                <a
                  href="https://www.instagram.com/sublimat_bella/?hl=es"
                  target="_blank"
                  className="botondir"
                  rel="noreferrer">
                  <AiOutlineInstagram />
                  sublimat_bella
                </a>
              </li>
              <li className="redesitems">
                <div className="botondir">
                  <AiOutlineWhatsApp />
                  +507 6596-9975
                </div>
              </li>
              <li className="redesitems">
                <div className="botondir">
                  <AiOutlineMail />
                  soporte@sublimatbella.com
                </div>
              </li>
              <li className="redesitems">
                <a
                  href="https://www.google.com/maps/place/Sublimat+%26+Dise%C3%B1os+Bella/@8.8761585,-79.7943664,15z/data=!4m5!3m4!1s0x0:0xa59ca047ece886a7!8m2!3d8.8761591!4d-79.7943726"
                  target="_blank"
                  className="botondir"
                  rel="noreferrer">
                  <IoLocationOutline />
                  Ubicación
                </a>
              </li>
            </ul>
          </div>
          <nav className="menupiedepagina">
            <h4>Acerca de</h4>
            <button onClick={backToTop}>Inicio</button>
            <Link className="a" to="/terminos" target="_blank">
              Terminos y condiciones
            </Link>
            <Link className="a" to="/privacidad" target="_blank">
              Políticas de privacidad
            </Link>
          </nav>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
