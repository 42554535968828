import Swal from "sweetalert2";
import { correctloaded, erroranimation, loadanimation } from "./Cargas";
import { auth, db, storage } from "./firebase";

const initialState = {
  solicitudes: [],
};

const SOLICITUD = "SOLICITUD";

export default function solicitudReducer(state = initialState, action) {
  switch (action.type) {
    case SOLICITUD:
      return { ...state, solicitudes: action.payload };
    default:
      return state;
  }
}

export const solicitarverificacion =
  (userid, file, correo, username) => async (dispatch) => {
    try {
      loadanimation();
      const imagenRef = storage
        .ref("admin")
        .child("solicitudes")
        .child(userid)
        .child("cedula");
      await imagenRef.put(file);
      const imagenURL = await imagenRef.getDownloadURL();
      await db.collection("Solicitudes").doc(userid).set({
        foto: imagenURL,
        correo: correo,
        username: username,
      });
      correctloaded("Solicitud enviada!");
    } catch (error) {
      console.log(error);
      erroranimation();
    }
  };

export const obtenersolici = () => async (dispatch) => {
  try {
    const arra = await db.collection("Solicitudes").get();
    const datos = await arra.docs.map((doc) => ({
      usuario: doc.id,
      ...doc.data(),
    }));
    dispatch({
      type: SOLICITUD,
      payload: datos,
    });
  } catch (error) {
    console.log(error);
  }
};

export const verificar = (userid) => async (dispatch) => {
  try {
    loadanimation();
    //    correctloaded(`${userid} Si llega hasta aqui...!!!`);
    const useridentification = await db.collection("users").doc(userid).get();
    if (useridentification.exists) {
      await db.collection("users").doc(userid).update({
        verified: true,
      });
      await db.collection("Solicitudes").doc(userid).delete();
      const imagenRef = await storage
        .ref("admin")
        .child("solicitudes")
        .child(userid)
        .child("cedula");
      await imagenRef.delete();
      dispatch(obtenersolici());
      Swal.close();
    } else {
      erroranimation(
        "Puede que el usuario ya no este registrado, se esta porcediendo a eliminar los datos del usuario"
      );
      await db.collection("Solicitudes").doc(userid).delete();
      const imagenRef = await storage
        .ref("admin")
        .child("solicitudes")
        .child(userid)
        .child("cedula");
      await imagenRef.delete();
      dispatch(obtenersolici());
      Swal.close();
    }
  } catch (error) {
    console.log(error);
  }
};

export const rechazar = (userid) => async (dispatch) => {
  try {
    loadanimation();
    const useridentification = await db.collection("users").doc(userid).get();
    if (useridentification.exists) {
      await db.collection("Solicitudes").doc(userid).delete();
      const imagenRef = await storage
        .ref("admin")
        .child("solicitudes")
        .child(userid)
        .child("cedula");
      await imagenRef.delete();
      dispatch(obtenersolici());
      Swal.close();
    } else {
      erroranimation(
        "Puede que el usuario ya no este registrado, se esta porcediendo a eliminar los datos del usuario"
      );
      await db.collection("Solicitudes").doc(userid).delete();
      const imagenRef = await storage
        .ref("admin")
        .child("solicitudes")
        .child(userid)
        .child("cedula");
      await imagenRef.delete();
      dispatch(obtenersolici());
      Swal.close();
    }
  } catch (error) {
    console.log(error);
  }
};
